import { staticFile  } from 'remotion';
export const categorisedMusicList: any = {
  Fashion: {
    30: {
      PAS: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/81571/element.wav',
      ],
      AIDA: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/68630/element.wav',
      ],
    },
    60: {
      PAS: [
        // {
        //   name: 'I am Free',
        //   url: 'https://content.typeframes.com/audio/i-m-free.mp3',
        //   duration: '03:18',
        // },
       
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/79970/element.wav',
          name: 'Deep Dream',
          duration: '00:34',
        },
        {
          name: 'Dont Blink',
          url: 'https://www.typeframes.com/audio/dont-blink.mp3',
          duration: '00:52',
        },
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/83740/element.wav',
          name: 'Runway Groove',
          duration: '00:34',
        },
        // {
        //   name: 'Future Bass',
        //   url: 'https://content.typeframes.com/audio/for-future-bass.mp3',
        //   duration: '02:28',
        // },
        // {
        //   name: 'Snowfall',
        //   url: 'https://content.typeframes.com/audio/snowfall.mp3',
        //   duration: '00:18',
        // },

        // {
        //   url: 'https://daqrukw5ofwla.cloudfront.net/public_element/76330/element.wav',
        //   name: 'Dark Hold',
        //   duration: '00:51',
        // },
      ],
    },
  },
  'E-commerce': {
    30: {
      PAS: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/25457/element.wav',
      ],
      FAB: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/68630/element.wav',
      ],
    },
    60: {
      PAS: [
        {
          name: 'Motion',
          url: 'https://www.typeframes.com/audio/motion.mp3',
          duration: '01:18',
        },
        // {
        //   name: 'Electronic',
        //   url:
        //     'https://content.typeframes.com/audio/beauteous-upbeat-electronic.mp3',
        //   duration: '01:51',
        // },
        // {
        //   url: 'https://daqrukw5ofwla.cloudfront.net/public_element/92637/element.wav',
        //   name: 'Neon Lights',
        //   duration: '00:43',
        // },
        // {
        //   name: 'Paris Else',
        //   url: 'https://content.typeframes.com/audio/paris-else.mp3',
        //   duration: '00:23',
        // },
        // {
        //   url: 'https://daqrukw5ofwla.cloudfront.net/public_element/62195/element.wav',
        //   name: 'Fashion dream',
        //   duration: '00:30',
        // },
      ],
    },
  },
  'Travel & Tourism': {
    30: {
      PAS: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/38308/element.wav',
      ],
    },
    60: {
      PAS: [
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/79970/element.wav',
          name: 'Deep Dream',
          duration: '00:34',
        },
        // {
        //   name: 'Futuristic Beat',
        //   url: 'https://content.typeframes.com/audio/futuristic-beat.mp3',
        //   duration: '02:01',
        // },
        // {
        //   url: 'https://daqrukw5ofwla.cloudfront.net/public_element/62195/element.wav',
        //   name: 'Fashion dream',
        //   duration: '00:30',
        // },
        // {
        //   name: 'The Best Jazz Club',
        //   url:
        //     'https://content.typeframes.com/audio/the-best-jazz-club-in-new-orleans.mp3',
        //   duration: '02:00',
        // },
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/76330/element.wav',
          name: 'Dark Hold',
          duration: '00:51',
        },
      ],
    },
  },
  'Real Estate': {
    30: {
      PAS: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/81571/element.wav',
      ],
    },
    60: {
      PAS: [
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/32580/element.wav',
          name: 'Binary Beats',
          duration: '00:30',
        },

        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/84257/element.wav',
          name: 'Travel Light',
          duration: '00:30',
        },

        // {
        //   name: 'Science Documentary',
        //   url: 'https://content.typeframes.com/audio/science-documentary.mp3',
        //   duration: '02:07',
        // },
        // {
        //   name: 'Once In Paris',
        //   url: 'https://content.typeframes.com/audio/once-in-paris.mp3',
        //   duration: '02:12',
        // },
      ],
    },
  },
  'Consumer Goods': {
    30: {
      PAS: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/68630/element.wav',
      ],
    },
    60: {
      PAS: [
        {
          name: 'Drops',
          url: 'https://www.typeframes.com/audio/drops.mp3',
          duration: '01:46',
        },
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/76330/element.wav',
          name: 'Dark Hold',
          duration: '00:51',
        },

        // {
        //   name: 'Nas',
        //   url: 'https://content.typeframes.com/audio/nas.mp3',
        //   duration: '04:55',
        // },
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/19311/element.wav',
          name: 'Lets Rock',
          duration: '00:30',
        },
      ],
    },
  },
  'Food & Restaurants': {
    30: {
      PAS: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/23602/element.wav',
      ],
      USP: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/68630/element.wav',
      ],
    },
    60: {
      PAS: [
        // {
        //   name: 'Paris Else',
        //   url: 'https://content.typeframes.com/audio/paris-else.mp3',
        //   duration: '00:23',
        // },
        {
          name: 'Claps',
          url: 'https://www.typeframes.com/audio/claps.mp3',
          duration: '01:30',
        },
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/84257/element.wav',
          name: 'Travel Light',
          duration: '00:30',
        },
        // {
        //   name: 'Constellations',
        //   url: 'https://content.typeframes.com/audio/constellations.mp3',
        //   duration: '03:43',
        // },
      ],
    },
  },
  'Professional Services': {
    30: {
      PAS: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/22666/element.wav',
      ],
    },
    60: {
      PAS: [
        {
          name: 'Stomp',
          url: 'https://www.typeframes.com/audio/stomp.mp3',
          duration: '00:25',
        },
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/19311/element.wav',
          name: 'Lets Rock',
          duration: '00:30',
        },

        // {
        //   name: 'Abstract Future',
        //   url:
        //     'https://content.typeframes.com/audio/dont-stop-me-abstract-future-bass.mp3',
        //   duration: '02:30',
        // },

        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/62195/element.wav',
          name: 'Fashion dream',
          duration: '00:30',
        },
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/84257/element.wav',
          name: 'Travel Light',
          duration: '00:30',
        },
      ],
    },
  },
  Sports: {
    30: {
      PAS: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/49511/element.wav',
      ],
    },
    60: {
      PAS: [
        {
          name: 'Snaps',
          url: 'https://www.typeframes.com/audio/snaps.mp3',
          duration: '01:12',
        },
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/19311/element.wav',
          name: 'Lets Rock',
          duration: '00:30',
        },

        // {
        //   name: 'Titanium',
        //   url: 'https://content.typeframes.com/audio/titanium.mp3',
        //   duration: '01:46',
        // },
        // {
        //   name: 'Blade Runner',
        //   url: 'https://content.typeframes.com/audio/bladerunner-2049.mp3',
        //   duration: '03:47',
        // },
      ],
    },
  },
  'Software & IT Industry': {
    30: {
      PAS: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/49511/element.wav',
      ],
    },
    60: {
      PAS: [
        {
          name: 'Snaps',
          url: 'https://www.typeframes.com/audio/snaps.mp3',
          duration: '01:12',
        },
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/19311/element.wav',
          name: 'Lets Rock',
          duration: '00:30',
        },

        // {
        //   name: 'Titanium',
        //   url: 'https://content.typeframes.com/audio/titanium.mp3',
        //   duration: '01:46',
        // },
        // {
        //   name: 'Blade Runner',
        //   url: 'https://content.typeframes.com/audio/bladerunner-2049.mp3',
        //   duration: '03:47',
        // },
      ],
    },
  },
  Others: {
    30: {
      PAS: [
        'https://daqrukw5ofwla.cloudfront.net/public_element/49511/element.wav',
      ],
    },
    60: {
      PAS: [
        // {
        //   name: 'Hotline',
        //   url: 'https://content.typeframes.com/audio/hotline.mp3',
        //   duration: '04:09',
        // },
        {
          name:'Best Jazz',
          url:'audio/backGroundMusic/Best Jazz.mp3',
          duration: '01:06',
        },
        {
          name:'Echos of Time',
          url:'audio/backGroundMusic/Echos of Time.mp3',
          duration: '00:40',
        },
        {
          name:'Hype Machine',
          url:'audio/backGroundMusic/Hype Machine.mp3',
          duration: '01:00',
        },
        {
          name:'Neon Rush',
          url:'audio/backGroundMusic/Neon Rush.mp3',
          duration: '01:12',
        },
        {
          name:'Rythm Rush',
          url:'audio/backGroundMusic/Rythm Rush.mp3',
          duration: '00:46',
        },
        {
          name:'Skyline High',
          url:'audio/backGroundMusic/Skyline High.mp3',
          duration: '01:00',
        },
        {
          name:'Tokyo Lights',
          url:'audio/backGroundMusic/Tokyo Lights.mp3',
          duration: '00:43',
        },
        {
          url:
            'https://daqrukw5ofwla.cloudfront.net/public_element/19311/element.wav',
          name: 'Lets Rock',
          duration: '00:30',
        },
      ],
    },
  },
};

export const categorisedVoiceOvers: any = {
  Fashion: {
    Simple: {
      name: 'Cali',
      voiceID: 'D2tR12Rck1jLExZMUIN2',
    },
    Burst: {
      name: 'Keli',
      voiceID: 'xK08HeIWKg8cNUG9eRAA',
    },
  },
  Sports: {
    Simple: {
      name: 'John Doe - Intimate + Narrative + Calm',
      voiceID: '7fbQ7yJuEo56rYjrYaEh',
    },
    Burst: {
      name: 'Linus - A young American tech video ads',
      voiceID: '4tCm63OwnXrer5fISFJg',
    },
  },
  'E-commerce': {
    Simple: {
      name: 'Cali',
      voiceID: 'D2tR12Rck1jLExZMUIN2',
    },
    Burst: {
      name: 'Jasmin- Social Media',
      voiceID: 'NjmTDfH4OzPkadkf2mCG',
    },
  },
  'Travel & Tourism': {
    Simple: {
      name: 'Sarah',
      voiceID: 'EXAVITQu4vr4xnSDxMaL',
    },
    Burst: {
      name: 'Keli',
      voiceID: 'xK08HeIWKg8cNUG9eRAA',
    },
  },
  'Real Estate': {
    Simple: {
      name: 'Sarah',
      voiceID: 'EXAVITQu4vr4xnSDxMaL',
    },
    Burst: {
      name: 'Keli',
      voiceID: 'xK08HeIWKg8cNUG9eRAA',
    },
  },
  'Consumer Goods': {
    Simple: {
      name: 'Sarah',
      voiceID: 'EXAVITQu4vr4xnSDxMaL',
    },
    Burst: {
      name: 'Natasha - Valley girl',
      voiceID: 'pN7T8o8U3IlDwB59xmM8',
    },
  },
  'Food & Restaurants': {
    Simple: {
      name: 'Sarah',
      voiceID: 'EXAVITQu4vr4xnSDxMaL',
    },
    Burst: {
      name: 'Sarah',
      voiceID: 'EXAVITQu4vr4xnSDxMaL',
    },
  },
  'Professional Services': {
    Simple: {
      name: 'Sarah',
      voiceID: 'EXAVITQu4vr4xnSDxMaL',
    },
    Burst: {
      name: 'Sarah',
      voiceID: 'EXAVITQu4vr4xnSDxMaL',
    },
  },
  'Software & IT Industry': {
    Simple: {
      name: 'Cali',
      voiceID: 'D2tR12Rck1jLExZMUIN2',
    },
    Burst: {
      name: 'Natasha - Valley girl',
      voiceID: 'pN7T8o8U3IlDwB59xmM8',
    },
  },
  Others: {
    Simple: {
      name: 'Sarah',
      voiceID: 'EXAVITQu4vr4xnSDxMaL',
    },
    Burst: {
      name: 'Sarah',
      voiceID: 'EXAVITQu4vr4xnSDxMaL',
    },
  },
};

export const platformVoiceOvers: any = {
  '1:1': {
    Simple: {
      name: 'David - British Storyteller + Calm + Narrative',
      voiceID: 'BNgbHR0DNeZixGQVzloa',
    },
    Burst: {
      name: 'Anthony Francis - Narration + Story + Casual',
      voiceID: 'sjwRAsCdMJodJszgJ6Ks',
    },
  },
  '16:9': {
    Simple: {
      name: 'Lucy - sweet and sensual + social media',
      voiceID: 'LcfcDJNUP1GQjkzn1xUU',
    },
    Burst: {
      name: 'David - British Storyteller + Calm + Narrative',
      voiceID: 'BNgbHR0DNeZixGQVzloa',
    },
  },
  '9:16': {
    Simple: {
      name: 'Lucy - sweet and sensual + social media',
      voiceID: '8SATUhYxhj0JidvjWPgK',
    },
    Burst: {
      name: 'Knightley - dapper and deep narrator',
      voiceID: 'LkImH5CNLcFcnABsxvCx',
    },
  },
};

export const whisperAiVoiceList = [
  {
    label: 'Alloy',
    value: 'alloy',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/alloy.wav',
  },
  {
    label: 'Echo',
    value: 'echo',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/echo.wav',
  },
  {
    label: 'Fable',
    value: 'fable',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/fable.wav',
  },
  {
    label: 'Onyx',
    value: 'onyx',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/onyx.wav',
  },
  {
    label: 'Nova',
    value: 'nova',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/nova.wav',
  },
  {
    label: 'Shimmer',
    value: 'shimmer',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/shimmer.wav',
  },
];

export const categorisedMusicListTwo: any = {
  Simple: {
    '16:9': [
      {
        name: 'Stomp',
        url: 'https://www.typeframes.com/audio/stomp.mp3',
        duration: '00:25',
      },
    ],

    '9:16': [
      {
        name: 'Stomp',
        url: 'https://www.typeframes.com/audio/stomp.mp3',
        duration: '00:25',
      },
      {
        name: 'Claps',
        url: 'https://www.typeframes.com/audio/claps.mp3',
        duration: '01:30',
      },
    ],
    '1:1': [
      {
        name: 'Stomp',
        url: 'https://www.typeframes.com/audio/stomp.mp3',
        duration: '00:25',
      },
    ],
  },

  Burst: {
    '16:9': [
      {
        name: 'Stomp',
        url: 'https://www.typeframes.com/audio/stomp.mp3',
        duration: '00:25',
      },
    ],

    '9:16': [
      {
        name: 'Stomp',
        url: 'https://www.typeframes.com/audio/stomp.mp3',
        duration: '00:25',
      },
      // {
      //   name: 'I am Free',
      //   url: 'https://content.typeframes.com/audio/i-m-free.mp3',
      //   duration: '03:18',
      // },
      {
        name: 'Motion',
        url: 'https://www.typeframes.com/audio/motion.mp3',
        duration: '01:18',
      },
    ],
    '1:1': [
      {
        name: 'Stomp',
        url: 'https://www.typeframes.com/audio/stomp.mp3',
        duration: '00:25',
      },
    ],
  },

  Prompt: {
    '16:9': [
      // {
      //   name: 'Blade Runner',
      //   url: 'https://content.typeframes.com/audio/bladerunner-2049.mp3',
      //   duration: '03:47',
      // },
      // {
      //   name: 'Paris Else',
      //   url: 'https://content.typeframes.com/audio/paris-else.mp3',
      //   duration: '00:23',
      // },
      {
        url:
          'https://daqrukw5ofwla.cloudfront.net/public_element/79970/element.wav',
        name: 'Deep Dream',
        duration: '00:34',
      },
    ],

    '9:16': [
      {
        name: 'Motion',
        url: 'https://www.typeframes.com/audio/motion.mp3',
        duration: '01:18',
      },
      {
        name: 'Claps',
        url: 'https://www.typeframes.com/audio/claps.mp3',
        duration: '01:30',
      },
      {
        name: 'Stomp',
        url: 'https://www.typeframes.com/audio/stomp.mp3',
        duration: '00:25',
      },
    ],
    '1:1': [
      {
        name: 'Stomp',
        url: 'https://www.typeframes.com/audio/stomp.mp3',
        duration: '00:25',
      },
    ],
  },
};
