import { IS_FREE_PLATFORM, NODE_BACKEND_URL } from '@src/config';
import { useGlobalContext } from '@src/context/globalContext';
import { Select, message } from 'antd';
import { useState } from 'react';
import axios from 'axios';
import { aiTextToSpeech, generateCaptions, getAudioDurationInSecondsCustom } from '@src/shared/utils/VideoGeneration/audioGeneration';
import { getDomainID, getToken } from '@src/config/config';
import { FONT_TEMPLATE } from '@src/data/captions';
import { generateSubtitles } from '@src/shared/utils/captions';
import { creditsDeduct, getUserDetails } from '@src/shared/utils/VideoGeneration/helperFunctions/mediaSearch';
const languagesOptions: any = [
  { value: 'English', label: <span>{'English'}</span> },
  { value: 'Spanish', label: <span>{'Spanish'}</span> },
  { value: 'Portuguese', label: <span>{'Portuguese'}</span> },
  { value: 'Italian', label: <span>{'Italian'}</span> },
  { value: 'Arabic', label: <span>{'Arabic'}</span> },
  { value: 'Japanese', label: <span>{'Japanese'}</span> },
  { value: 'Chinese', label: <span>{'Chinese'}</span> },
  { value: 'German', label: <span>{'German'}</span> },
  { value: 'Hindi', label: <span>{'Hindi'}</span> },
  { value: 'French', label: <span>{'French'}</span> },
  { value: 'Korean', label: <span>{'Korean'}</span> },
  { value: 'Indonesian', label: <span>{'Indonesian'}</span> },
  { value: 'Dutch', label: <span>{'Dutch'}</span> },
  { value: 'Turkish', label: <span>{'Turkish'}</span> },
  { value: 'Filipino', label: <span>{'Filipino'}</span> },
  { value: 'Polish', label: <span>{'Polish'}</span> },
  { value: 'Swedish', label: <span>{'Swedish'}</span> },
  { value: 'Bulgarian', label: <span>{'Bulgarian'}</span> },
  { value: 'Romanian', label: <span>{'Romanian'}</span> },
  { value: 'Czech', label: <span>{'Czech'}</span> },
  { value: 'Greek', label: <span>{'Greek'}</span> },
  { value: 'Finnish', label: <span>{'Finnish'}</span> },
  { value: 'Croatian', label: <span>{'Croatian'}</span> },
  { value: 'Malay', label: <span>{'Malay'}</span> },
  { value: 'Slovak', label: <span>{'Slovak'}</span> },
  { value: 'Danish', label: <span>{'Danish'}</span> },
  { value: 'Tamil', label: <span>{'Tamil'}</span> },
  { value: 'Ukrainian', label: <span>{'Ukrainian'}</span> },
  { value: 'Russian', label: <span>{'Russian'}</span> },

];
export const TranslateAudio = ({store, translateloading, setTranslateloading, aiVoicesResponse}:any) => {
  const {
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [scriptDetails, setScriptDetails],
  } = useGlobalContext();

  const [selectedLanguage, setSelectedLanguage] = useState(
    userDetails?.purpose?.language
  );
  const [wordsPerLine, setWordsPerLine] = useState(
    store.pages[0].custom?.template
      ? store.pages[0].custom?.template.wordsPerLine
      : FONT_TEMPLATE[0].wordsPerLine
  );
  const [yAxisPosition, setYAxisPosition] = useState(
    store.height - store.height * 0.2
  );
  const [fontTemplate, setFontTemplate] = useState<any>(
    store.pages[0].custom?.template
      ? store.pages[0].custom?.template
      : { ...FONT_TEMPLATE[0], position: store.height - store.height * 0.2 }
  );
  const [fontSize, setFontSize] = useState(
    store.pages[0].custom?.template
      ? store.pages[0].custom?.template.fontSize
      : FONT_TEMPLATE[0].fontSize
  );
  
  const handleLanguageChange = async (value: any) => {
    setTranslateloading(true)

    const getCredits = await getUserDetails()
    if(getCredits == -1){
      message.warning('Something went wrong!')
    setTranslateloading(false)

      return
    }
    if(getCredits?.voice_limit < 1){
      message.warning('Voice credits have been exhausted. Please upgrade the plan to use the voice.')
    setTranslateloading(false)

    return
    }

    setTranslateloading(true)
    const currentLang=selectedLanguage
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    });
    
    try{
      let totalDurationInSeconds=0
        for (let index = 0; index < scriptDetails.data.length; index++) {
            const script = scriptDetails.data[index];
            if(script?.voiceOver.length>0){
              const postBody = {
                  text: script?.voiceOver,
                  currentLanguage: currentLang,
                  outputLanguage: value,
                };
                try {
                  const translateText = await localEndPoint.post(
                    '/api/v1/openAi/translate-script',
                    postBody
                  );
                  let domainId
                  let token
              
                  if (IS_FREE_PLATFORM === 'false') {
                    domainId = getDomainID()
                    token = getToken()
                  }
               
                  const voice = aiVoicesResponse?.voices?.find((voice: any) => voice.name === script?.name)
                  // console.log(voice)
                  const voiceId=script?.elevenLabsAiVoiceId || voice?.id || 'EXAVITQu4vr4xnSDxMaL'
                  const translatedText=translateText.data
                  const audioUrl = await aiTextToSpeech(scriptDetails.data.length === index ? translatedText : translatedText+'..', voiceId, domainId, script?.name || '', script?.isElevenApi || true, token, '', '')
                  let duration = 2
                  if(audioUrl) duration = await getAudioDurationInSecondsCustom(audioUrl) 
                  let newDuration =duration * 1000 > store.pages[index].duration ? duration * 1000 + 500 : store.pages[index].duration + 500
                  if (index === store.pages.length - 1) {
                      newDuration = newDuration + 1000
                    }
                    let captionsResult: any
                    try {
                      captionsResult = await generateCaptions(audioUrl, value)
                    } catch (error) {
                      console.error('Caption generation failed, proceeding without captions.', error)
                    }finally{
                      // setTranslateloading(false)
                    }
                    await setScriptDetails((prevScript: { data: any }) => {
                      const updatedScript = { ...prevScript }
                
                      const updatedData = [...prevScript.data ? prevScript.data : prevScript]
                
                      updatedData[index] = {
                        ...updatedData[index],
                        voiceOver:translatedText,
                        url: audioUrl,
                        isElevenApi: true,
                        captions: captionsResult ? [{ words: captionsResult.data.words, text: translatedText }] : [],
                        aiVoiceVolume: 1,
                        sounds: [{ url: audioUrl, audioDuration: duration }],
                      }
                
                      return { ...updatedScript, data: updatedData, showSubtitles: true }
                    })
          
                    if (captionsResult) {
                      store.pages[index].set({
                        custom: {
                          ...store.pages[index].custom,
                          aiVoiceVolume: 1,
                          sounds: [{ url: audioUrl, audioDuration: duration }],
                          captions: [{ text: translatedText, words: captionsResult.data.words }],
                        },
                      })
                    } else {
                      store.pages[index].set({
                        aiVoiceVolume: 1,
                        custom: {
                          ...store.pages[index].custom,
                          sounds: [{ url: audioUrl, audioDuration: duration }],
                        },
                      })
                    }
                    totalDurationInSeconds=totalDurationInSeconds+duration
                 
                } catch (error) {
                  console.error(error);
                }
            }
          
          }
         await generateSubtitles(
            undefined,
            undefined,
            undefined,
            store,
            wordsPerLine,
            fontTemplate,
            true,
            yAxisPosition,
            setYAxisPosition,
            setScriptDetails
          );
        
          message.success(`Video translated from ${currentLang} to ${value}!`)
          setSelectedLanguage(value)
          setUserDetails((prevState:any) => ({
              ...prevState,
              purpose: {
                ...prevState.purpose,
                language: value,
              },
            }));
            setTranslateloading(false)
            const audioDurationInMins = Math.ceil(totalDurationInSeconds / 60)
            creditsDeduct('voice_credits', audioDurationInMins)

    }catch(error){
        message.warning(`Something went wrong.`)
    }
   
      
  };
  return (
    <>
      <Select
        style={{ minWidth: '200px', width: '100%' }}
        placeholder='Select Language'
        optionFilterProp='children'
        onChange={handleLanguageChange}
        value={selectedLanguage}
        defaultValue={selectedLanguage}
        //   dropdownRender={(menu) => (
        //     <div>
        //       {menu}
        //       <audio ref={audioRef} src={playingUrl} style={{ display: 'none' }} />
        //     </div>
        //   )}
        options={languagesOptions}
        disabled={translateloading}
      />

    </>
  );
};
