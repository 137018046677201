import { TransitionSeries, springTiming } from '@remotion/transitions';
import React from 'react';
import { Audio, Sequence, interpolate, staticFile  } from 'remotion';
import {
  burstImageTransitionFunctions,
  transitionAnimationsDropDown,
} from '../../../data/transitions';
import { generateUniqueId } from '../../../shared/utils/core';
import './fonts.css';
import { SinglePage } from './singlePage';
import { AnimatedSubtitles } from './subtitles';
import { useGlobalContext } from '../../../context/globalContext';
import { slideEffect } from '../../../assets';
import { addSound } from './addSound';
import RenderPosterComponent from './renderPoster';
import RenderPosterComponentTwo from './renderPosterComp';
import { loadFont } from "@remotion/fonts";
import { millisecondsToFrames } from '../../../shared/utils/remotion';
// import { getAvailableFonts } from "@remotion/google-fonts";
// import { useGlobalContext } from '../../context/globalContext'

interface HtmlProp {
  htmlData: string;
  jsonData: any;
  scriptDetails: any;
  width: number;
  height: number;
  transitionDurationInFrames: number;
  transitionAnimationProp: any;
  isDownload: boolean;
  burstImagesTransitionAnimationArray: any;
  videoStyle: string;
}
// TO-DO -> Make this dynamic from user input - Hard coded FPS
const fps = 30;
const delayInSeconds = 1;

export const MyCompositionHtmlComponent: React.FC<HtmlProp> = ({
  htmlData,
  jsonData,
  scriptDetails,
  width,
  height,
  transitionDurationInFrames,
  transitionAnimationProp,
  isDownload,
  burstImagesTransitionAnimationArray,
  videoStyle,
}) => {
  // console.log(scriptDetails)
  loadFont({
    family: "Konga next",
    url: staticFile("fonts/Konga Next.otf"),
  },
  
).then(() => console.log("Font loaded!"));
loadFont({
  family: "THE BOLD FONT",
  url: staticFile("fonts/THE BOLD FONT.otf"),
},

).then(() => console.log("Font loaded!"));
  const audioFade = scriptDetails?.audioFade || false
  const transitionFunction: any =
    transitionAnimationsDropDown.find(
      (item) => item.value === transitionAnimationProp
    )?.functions || transitionAnimationsDropDown[0].functions;
  
  const customTransitionEffect = scriptDetails?.customTransitionSoundurl || 'https://d2tqfxp1334laf.cloudfront.net/image_projects/da764da2-2982-49be-88bd-eca414c4f5d7/assets/audio/e4cdb276-d1ad-4d81-91ae-3182d55c4099/audio.mp3'  
  const transitionSoundEffect = scriptDetails?.customtransitionSound ? customTransitionEffect : `audio/transitions/${transitionAnimationProp}.mp3`
  
  /********* Get Caption styles and check if we need to show them or not *********/
  const captionsTextStyle = scriptDetails.template;
  const showSubtitles = scriptDetails.showSubtitles;
  // console.log(scriptDetails)
  const newCaptionStyle = { ...captionsTextStyle, position: captionsTextStyle.position-100 };
  const verticalCaptionStyle =  { ...captionsTextStyle, position: captionsTextStyle.position+100 }
  // const newcaptionStyle=captionsTextStyle
  // newcaptionStyle['position']=208
  /********* Get Background music URL and volume and voiceover volume *********/
  
  const backgroundMusicUrl = scriptDetails?.backgroundMusic.url.startsWith('audio/') ? staticFile(scriptDetails?.backgroundMusic.url) : scriptDetails?.backgroundMusic.url;
  const backgroundMusicVolume = scriptDetails?.backgroundMusic.backgroundVolume;
  const backgroundmusicDurationInMili=scriptDetails?.backgroundMusic.duration*1000
  const backgroundmusicInframes= millisecondsToFrames(backgroundmusicDurationInMili, 30)
  const voiceOverVolume = scriptDetails?.aiVoiceVolume
    ? scriptDetails?.aiVoiceVolume
    : 0.7;
  
  const transitionSound = scriptDetails?. transitionSound || false
  const aiVoiceSpeed=scriptDetails?.aiVoiceSpeed || 1
  /********* Store last screen index for transition check *********/
  const lastScreen = scriptDetails?.data.length - 1;
  
  let accumulatedFrames = 0;

  let pageIndex = 0;
    // console.log(console.log(getAvailableFonts())
  // )
  const accumulatedFramesArray: any =[]
//  console.log(scriptDetails)
  return (
    <>
      <TransitionSeries>


      <TransitionSeries.Sequence
                key={`thumbnail-sequence`}
                durationInFrames={2}
              >
               <RenderPosterComponentTwo
                height={height}
                width={width}
               thumbnailImage={scriptDetails.thumbnailImage} 
               thumbnailMessage={scriptDetails.data[0].voiceOver}
               />
          
      </TransitionSeries.Sequence>
        {scriptDetails.data.map((page: any, index: number) => {
          /********* Get the page JSON and HTML if Simple video -> 1 page === 1 JSON page. Indexes are ordered between both objects *********/
          const jsonPage = jsonData.pages[index];
          const pageHtml = htmlData[index];

          /********* Get the JSON and HTML of all pages that are linked to this page object. 1 page object ===  page.images.length No of pages *********/
          const jsonPages: any = [];
          const htmlPages: any = [];
          let durationInFrames: any = 0;

          if (videoStyle === 'Burst') {
            /********* Loop over pages and add them to an array of JSON and HTML pages array until the loop matches pageIndex + page.images.length *********/
            for (let i = pageIndex; i < pageIndex + page.images.length; i++) {
              jsonPages.push(jsonData.pages[i]);
              htmlPages.push(htmlData[i]);
              durationInFrames += (jsonData.pages[i].duration / 1000) * fps;
            }
            pageIndex += page.images.length;
          } else if (videoStyle === 'Simple') {
            jsonPages.push(jsonData.pages[pageIndex]);
            htmlPages.push(htmlData[pageIndex]);
            durationInFrames +=
              (jsonData.pages[pageIndex].duration / 1000) * fps;
            pageIndex++;
          }

          const sequenceKey = generateUniqueId();
          const transitionKey = generateUniqueId();
          
          const captions = page?.captions
            ? page?.captions[0]?.words
            : undefined;
          
          const voiceOver = page?.sounds ? page?.sounds[0]?.url : undefined;
          const voiceOverDuration = page?.sounds
            ? page?.sounds[0]?.audioDuration
            : undefined;

          const audioDelay = fps * delayInSeconds;

          const voiceOverDurationFrame = Math.round(
            (voiceOverDuration + delayInSeconds) * fps
          );

          // Page start and end frames
          accumulatedFramesArray.push(accumulatedFrames)
          const pageStartFrame = accumulatedFrames;
          const pageEndFrame =
            accumulatedFrames +
            durationInFrames +
            transitionDurationInFrames / 10;
            accumulatedFrames += durationInFrames;
            if(pageEndFrame>=backgroundmusicInframes){
              accumulatedFrames=0
            }
          let volume = backgroundMusicVolume ? backgroundMusicVolume : 0.5;
          const transitionBusrtAnimations =
            burstImagesTransitionAnimationArray[index];
       
          return (
            <>
              <TransitionSeries.Sequence
                key={`sequence-${index}-${sequenceKey}`}
                durationInFrames={durationInFrames + transitionDurationInFrames}
              >
                {/* Add background Audio in Transition so we can add sound fading using interpolation */}
                <Audio
                  // loop
                  src={backgroundMusicUrl}
                  startFrom={pageStartFrame}
                  endAt={pageEndFrame}
                  // startFrom={0}
                  // endAt={videoEndDurationInFrames}
                  volume={(f) => {
                   
                    if (audioFade) {
                      if (f <= audioDelay) {
                        volume = interpolate(
                          f,
                          [0, audioDelay],
                          [backgroundMusicVolume, backgroundMusicVolume / 10],
                          {
                            extrapolateLeft: 'clamp',
                            extrapolateRight: 'clamp',
                          }
                        );
                      } else if (f >= voiceOverDurationFrame) {
                        if (index === lastScreen) {
                          volume = interpolate(
                            f,
                            [
                              voiceOverDurationFrame,
                              durationInFrames + transitionDurationInFrames,
                            ],
                            [volume, volume / 10],
                            {
                              extrapolateLeft: 'clamp',
                              extrapolateRight: 'clamp',
                            }
                          );
                        } else {
                          volume = interpolate(
                            f,
                            [
                              voiceOverDurationFrame,
                              durationInFrames + transitionDurationInFrames,
                            ],
                            [volume, backgroundMusicVolume],
                            {
                              extrapolateLeft: 'clamp',
                              extrapolateRight: 'clamp',
                            }
                          );
                        }
                      } else if (
                        f >= audioDelay &&
                        f <= voiceOverDurationFrame
                      ) {
                        volume = backgroundMusicVolume / 10;
                      }
                      return volume;
                    }

                    return volume;
                  }}
                />
                {videoStyle === 'Burst' ? (
                  // If the videoStyle is Burst then render the Burst video where multiple images are played on one scene / Ad block type / Script Page
                  // This is a sequence of images with a transition between each image / jsonPage
                  <TransitionSeries>
                    {jsonPages.map((jsonPage: any, index: number) => {
                      // Get the transition animation function for the burst images
                      const transitionAnimationFunction: any =
                        burstImageTransitionFunctions.find(
                          (item) =>
                            item.value === transitionBusrtAnimations[index]
                        )?.function ||
                        burstImageTransitionFunctions[0].function;
                      //  Calculate the number of frames for each page / image
                      const pageFrames = (jsonPage.duration / 1000) * fps;
                      return (
                        <>
                          <TransitionSeries.Sequence
                            key={`transitionSeriesSequence-${index}`}
                            durationInFrames={pageFrames + 20}
                          >
                            <SinglePage
                              key={index}
                              pageHtml={htmlPages[index]}
                              width={width}
                              height={height}
                              pageData={jsonPage}
                              isDownload={isDownload}
                            />
                              {/* <Audio
                                src={staticFile('audio/slideEffect.mp3')}
                                volume={0.02}
                               
                              /> */}
                          </TransitionSeries.Sequence>
                          <TransitionSeries.Transition
                            key={`transitionSeriesTransition-${index}`}
                            presentation={transitionAnimationFunction}
                            timing={springTiming({
                              config: { damping: 200 },
                              durationInFrames: 20,
                              durationRestThreshold: 0.001,
                            })}
                          />
                        
                        </>
                      );
                    })}
                  </TransitionSeries>
                ) : (
                  // If the videoStyle is not Burst then render the Simple video where one Audio is played on one scene
                  <SinglePage
                    pageHtml={pageHtml}
                    width={width}
                    height={height}
                    pageData={jsonPage}
                    isDownload={isDownload}
                  />
                )}
                {/* Add a sequence series for AI Voice audio and subtitles */}
                <Sequence
                  from={0.5 * fps} // TO-DO -> Make this dynamic (editable by the user) - Delay for the AI Voice and Subtitles is hardcoded in the sequence so that the audio and subtitles start 0.5 seconds after the video
                  durationInFrames={
                    durationInFrames + transitionDurationInFrames
                  } // The duration of the AI Voice and Subtitles is the same as the video
                >
                  {/* // If AI Voice audio is available, add it to the sequence */}
                  {voiceOver && (
                    <Audio
                      src={voiceOver}
                      startFrom={0}
                      volume={voiceOverVolume}
                      playbackRate={aiVoiceSpeed}
                    />
                  
                  )}
                  {/* // If Captions are enabled and available, add them to the sequence */}
                  {captions &&
                    captionsTextStyle &&
                    showSubtitles &&
                   (
                      <AnimatedSubtitles
                        captions={captions}
                        width={width}
                        height={height}
                        isDownload={isDownload}
                        style={ width < height ? verticalCaptionStyle :newCaptionStyle}
                        // style={newCaptionStyle}

                      />
                    )}
                </Sequence>
              </TransitionSeries.Sequence>

              {/*
               Don't Add the transition series for the last scene as it will show a blank white screen in the end otherwise
               Add a transition series for the transition between scenes / Ad Block sections pages
               */}

              {index < jsonData.pages.length - 1 && (
              transitionSound?(
                <TransitionSeries.Transition
                key={`sequence-${index}-${transitionKey}`}
                presentation={addSound(transitionFunction, scriptDetails?.customtransitionSound ? transitionSoundEffect : staticFile(transitionSoundEffect))} // Adding transition sound along with the effect
                timing={springTiming({
                  config: { damping: 200 },
                  durationInFrames: transitionDurationInFrames, // The duration of the transition between scenes / Ad Block sections pages - This is passed from the transition panel in right sidebar
                  durationRestThreshold: 0.001,
                })}
              />
              ):(
                <TransitionSeries.Transition
                key={`sequence-${index}-${transitionKey}`}
                presentation={transitionFunction} 
                timing={springTiming({
                  config: { damping: 200 },
                  durationInFrames: transitionDurationInFrames, // The duration of the transition between scenes / Ad Block sections pages - This is passed from the transition panel in right sidebar
                  durationRestThreshold: 0.001,
                })}
              />
              )
              )}
            </>
          );
        })}
      </TransitionSeries>


    </>
  );
};

export const MyCompositionHtml = React.memo(MyCompositionHtmlComponent);
