import { RedoOutlined, UploadOutlined, VideoCameraAddOutlined } from '@ant-design/icons'
import { useGetGettyImageVideos } from '../../../../api/gettyImages/getGettyImageVideo'
import { useGetGettyImages } from '../../../../api/gettyImages/getGettyImages'
import { useGetPexelsVideos } from '../../../../api/pexels/getPexelsVideo'
import { useGetPixabayVideos } from '../../../../api/pixaby/getPixabayVideo'
import { useGetStoryblocksVideos } from '../../../../api/storyblocks/useGetStoryblocksVideo'
import { useGetAssets } from '../../../../api/useGetAssets'
import { PEXELS_KEY, PIXABY_KEY, IS_FREE_PLATFORM } from '../../../../config'
import { qaBackendApiInstance, getDomainID } from '../../../../config/config'
import { useGlobalContext } from '../../../../context/globalContext'
import { Button, Input, Tabs, message } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import { observer } from 'mobx-react-lite'
import { SectionTab } from 'polotno/side-panel'
import { useEffect, useState } from 'react'
import { VideoSearch } from '../videoSearchResult'
import { v4 as uuidv4 } from 'uuid';
import { useGetAiImages } from '../../../../api/useAiImageAssets'
import { generateUniqueId } from '../../../../shared/utils/core'
import { useGetDalleAiImages } from '@src/api/useDalleAssets'
interface VideoPanelProps {
  store: any
  elementID: string
  index: number
  isNotPanel?: boolean
  searchQueries: string[]
}

function getType(file: any) {
  const { type } = file
  if (type.indexOf('svg') >= 0) {
    return 'svg'
  }
  if (type.indexOf('image') >= 0) {
    return 'image'
  }
  if (type.indexOf('video') >= 0) {
    return 'video'
  }
  if (type.indexOf('audio') >= 0) {
    return 'audio'
  }
  return 'image'
}

export const VideoPanel = observer(({ store, elementID, index, isNotPanel, searchQueries }: VideoPanelProps) => {
  const {
    ScriptDetails: [scriptDetails],
    UserDetail: [userDetails],
  } = useGlobalContext()

  const [searchQueryIndex, setSearchQueryIndex] = useState(0)
  const [imagePage, setImagePage] = useState(1)
  const [videoPage, setVideoPage] = useState(1)
  const [isUploading, setUploading] = useState(false)
  const [images, setImages] = useState<any>([])
  const [videos, setVideos] = useState<any>([])

  const [selectedOrientationValue, setSelectedOrientationValue] = useState(
    userDetails?.purpose?.size === '9:16' ? 'vertical' : 'horizontal'
  )

  async function uploadFile(file: File): Promise<any> {
    const type = getType(file)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('asset_type', type)

    try {
      setUploading(true)
      const response = await qaBackendApiInstance.post(
        `/asset/upload?asset_type=${type}&domain_id=${getDomainID()}`,
        formData
      )
      if (response.status === 200) {
        console.log('File uploaded successfully', response.data)
        // setVideos(...videos, response.data)
        setVideos([...videos, response.data])
      } else {
        message.error('Upload failed with status: ' + response.status)
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      message.error('Upload failed.')
    } finally {
      type === 'image' && refetchImages()
      type === 'video' && refetchVideos()
      setUploading(false)
    }
  }

  function getExtension(filename:any) {
    return filename.split('.').pop()
  }
  const handleFileInput = async (e: any) => {

    const { target } = e
    // const files = target.files
    // if (files.length > 0) {
    //   const file = files[0];
    //   const videoUrl = URL.createObjectURL(file);
    //   const image_extensions = [
    //     'jpg', 'jpeg', 'jpe', 'jfif', 
    //     'png',                        
    //     'gif',                        
    //     'bmp', 'dib',                
    //     'tiff', 'tif',                
    //     'webp',                        
    //     'ico',                        
    //     'ppm',                       
    //     'pgm',                       
    //     'pbm',                        
    //     'pnm', 
    //     'svg',                       
       
    // ]
    //   const fileType=getExtension(file.name).toLowerCase()
    //   if (image_extensions.includes(fileType)){
        
    //       const newObj={
    //     id:uuidv4(),
    //     image_url:videoUrl,
    //     video_url: videoUrl,
    //     type: 'image'
    //   }
    //   setVideos((prevVideos:any) => [...prevVideos, newObj]);
    //   } else{
    //   const newObj={
    //     id:uuidv4(),
    //     image_url:videoUrl,
    //     video_url: videoUrl,
    //     type: 'video'
    //   }
    //   setVideos((prevVideos:any) => [...prevVideos, newObj]);
    //   }
      
    // }
    setUploading(true)
    for (const file of target.files) {
      await uploadFile(file)
    }
    setUploading(false)
    target.value = null
  }

  const [searchQuery, setSearchQuery] = useState(
    searchQueries ? searchQueries[0] : scriptDetails.data[0].searchQueries[searchQueryIndex]
  )
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500) // Debounce search input to reduce API calls

  const { data: pexelsVideos, isLoading } = useGetPexelsVideos(
    PEXELS_KEY,
    debouncedSearchQuery, // Use the debounced query value
    'landscape',
    'medium'
  )

  const {
    data: imageData,
    isLoading: isImagesLoading,
    isError,
    isSuccess: isImagesSuccess,
    error,
    refetch: refetchImages,
  } = useGetAssets(imagePage, 'image')

  const handleImageLoadMore = () => {
    setImagePage(imagePage + 1)
  }

  const handleVideoLoadMore = () => {
    setVideoPage(videoPage + 1)
  }

  // Transform video data to match what ImagesGrid expects
  const transformedPexelVideos = pexelsVideos?.videos.map(
    (video: { id: any; image: any; url: any; video_files: any; }) => {
      const videos = video.video_files.filter((file: { quality: string }) => file.quality === 'sd')
      return {
        id: video.id,
        image_url: video.image,
        video_url: videos[videos.length - 1]?.link,
        type: 'video',
      }
    }
  )

  const { data: pixabyVideo, isLoading: isPixbyLoading } = useGetPixabayVideos(PIXABY_KEY, debouncedSearchQuery)

  const transformedPixabayVideos = pixabyVideo?.hits.flatMap(
    (video: { id: any; videos: { large: { thumbnail: any; url: any } } }) => [
      {
        id: video.id,
        image_url: video.videos.large.thumbnail,
        video_url: video.videos.large.url,
        type: 'video',
      },
    ]
  )

  // use this for our Ai image api
  // const {data: aiImage, isLoading: isAiLoading} = useGetAiImages(debouncedSearchQuery)
  // const transformedAIImage = [{id: generateUniqueId(), image_url: aiImage?.background_image, video_url:aiImage?.background_image, type:'image'}]

  // use this for dalle ai image generation
  const {data: aiImage, isLoading: isAiLoading} = useGetDalleAiImages(debouncedSearchQuery, selectedOrientationValue)
  
  const transformedAIImage = [{id: generateUniqueId(), image_url:aiImage, video_url:aiImage, type:'image'}]

  const { data: storyBlocksVideos, isLoading: isSbLoading } = useGetStoryblocksVideos(debouncedSearchQuery)

  const transformedStoryBlocksVideos = storyBlocksVideos?.results.map((video: any) => {
    return {
      id: video.id,
      image_url: video.thumbnail_url,
      video_url: video.preview_urls._480p,
      type: 'video',
    }
  })

  const {
    data: gettyImageVideos,
    isLoading: isGettyImageVideoLoading,
    isSuccess: isGettyImageVideosSuccess,
  } = useGetGettyImageVideos(debouncedSearchQuery, userDetails?.purpose?.size, selectedOrientationValue)

  const transformedGettyImageVideos =
    isGettyImageVideosSuccess && gettyImageVideos?.videos
      ? gettyImageVideos.videos.map((video: any) => {
          const videoPreview = video.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'comp')
          const imagePreview = video.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'thumb')

          // Handle cases where find might return undefined
          return {
            id: video.id,
            image_url: imagePreview,
            video_url: videoPreview.uri,
            type: 'video',
          }
        })
      : []

  const {
    data: gettyImages,
    isLoading: isGettyImagesLoading,
    isSuccess: isGettyImagesSuccess,
  } = useGetGettyImages(debouncedSearchQuery, selectedOrientationValue, false)

  const transformedGettyImages =
    isGettyImagesSuccess && gettyImages
      ? gettyImages.map((image: any) => {
          const videoPreview = image.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'comp')
          const imagePreview = image.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'thumb')

          // Handle cases where find might return undefined
          return {
            id: image.id,
            image_url: imagePreview.uri,
            video_url: videoPreview.uri,
            type: 'image',
          }
        })
      : []

  const { data: videosData, refetch: refetchVideos } = useGetAssets(videoPage, 'video')

  useEffect(() => {
    if (imageData && imageData.items && imageData.items.length > 0) {
      setImages([...images, ...imageData.items])
    }
  }, [imageData])

  useEffect(() => {
    console.log(videosData)
    if (videosData && videosData.items && videosData.items.length > 0) {
      const imageVideoData: any[] = videosData.items.filter((obj: any) => obj.type !== 'audio');
      
      setVideos([...videos, ...imageVideoData])
    }
  }, [videosData])

  let transformedVideos = videos?.map((video: any) => {
    // console.log(videos)
    return {
      id: video.id,
      image_url: video.image_url ? video.image_url : undefined,
      video_url: video.video_url? video.video_url : undefined,
      type: video.type,
    }
  })
useEffect(()=>{
  transformedVideos = videos?.map((video: any) => {
    // console.log(videos)
    return {
      id: video.id,
      image_url: video.image_url,
      video_url: video.video_url,
      type: video.type,
    }
  })
}, [videos])

  const transformedImages = images?.map((image: any) => {
    return {
      id: image.id,
      image_url: image.image_url,
      video_url: undefined,
      type: 'image',
    }
  })

  return (
    <div style={{ height: '90%' }}>
      <Input
        placeholder='Search for videos'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: 16 }}
        suffix={
          isNotPanel === true && (
            <RedoOutlined
              onClick={() => {
                if (searchQueryIndex === searchQueries.length - 1) {
                  setSearchQueryIndex(0)
                } else {
                  setSearchQueryIndex(searchQueryIndex + 1)
                }
                setSearchQuery(searchQueries[searchQueryIndex])
              }}
            />
          )
        }
      />
      <>
        <Tabs defaultActiveKey={'0'}>
          {IS_FREE_PLATFORM === 'true' && (
            <TabPane tab='Getty Images' key='0'>
              <VideoSearch
                transformedElementsArray={transformedGettyImages}
                isLoading={isGettyImagesLoading}
                store={store}
                title={''}
                elementID={elementID}
                isNotPanel={isNotPanel}
                isGettyImage={true}
                dropDownValue={selectedOrientationValue}
                setSelectedValue={setSelectedOrientationValue}
              />
            </TabPane>
          )}
          {IS_FREE_PLATFORM === 'false' && (
            <>
              <TabPane tab='Getty Videos' key='0'>
                <VideoSearch
                  transformedElementsArray={transformedGettyImageVideos}
                  isLoading={isGettyImageVideoLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isGettyImage={true}
                  dropDownValue={selectedOrientationValue}
                  setSelectedValue={setSelectedOrientationValue}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane>
              <TabPane tab='Getty Images' key='1'>
                <VideoSearch
                  transformedElementsArray={transformedGettyImages}
                  isLoading={isGettyImagesLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  isGettyImage={true}
                  dropDownValue={selectedOrientationValue}
                  setSelectedValue={setSelectedOrientationValue}
                />
              </TabPane>
              <TabPane tab='Pexel' key='2'>
                <VideoSearch
                  transformedElementsArray={transformedPexelVideos}
                  isLoading={isLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane>
              <TabPane tab='AI Images' key='3'>
              {/* <Button
                // icon={<UploadOutlined />}
                style={{ width: '100%', paddingBottom: 20, marginBottom: 20 }}
                // loading={isUploading}
                // onClick={() => {
                //   document.getElementById('input-file')?.click()
                // }}
              >
                Generate Image
              </Button> */}
              <VideoSearch
                  transformedElementsArray={transformedAIImage}
                  isLoading={isAiLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane>
              {/* <TabPane tab='Storyblocks' key='3'>
                <VideoSearch
                  transformedElementsArray={transformedStoryBlocksVideos}
                  isLoading={isSbLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane>
              <TabPane tab='Pixaby' key='4'>
                <VideoSearch
                  transformedElementsArray={transformedPixabayVideos}
                  isLoading={isPixbyLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane> */}
            </>
          )}

          {isNotPanel === true && IS_FREE_PLATFORM === 'false' && (
            <TabPane tab='My Assets' key='5'>
              <Button
                icon={<UploadOutlined />}
                style={{ width: '100%', paddingBottom: 20, marginBottom: 20 }}
                loading={isUploading}
                onClick={() => {
                  document.getElementById('input-file')?.click()
                }}
              >
                Upload Asset
              </Button>

              <input
                type='file'
                id='input-file'
                style={{ display: 'none' }}
                onChange={handleFileInput}
                accept='image/*, video/*'
                multiple
              />
              <VideoSearch
                transformedElementsArray={transformedVideos}
                isLoading={isImagesLoading}
                store={store}
                title={''}
                elementID={elementID}
                index={index}
              />
              <Button
                style={{ width: '100%', marginTop: 20 }}
                onClick={handleVideoLoadMore}
                disabled={isLoading}
                loading={isLoading}
              >
                Load More
              </Button>
            </TabPane>
          )}
        </Tabs>
      </>
    </div>
  )
})

// define the new custom section
export const CustomVideoSearch = {
  name: 'video',
  Tab: (props: any) => {
    return (
      <SectionTab name={IS_FREE_PLATFORM === 'true' ? 'Image Search' : 'Video Search'} {...props}>
        <VideoCameraAddOutlined />
      </SectionTab>
    )
  },
  Panel: VideoPanel,
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    // Set debouncedValue to value after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // Cleanup function to clear the timeout if value or delay changes
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay]) // Only re-call effect if value or delay changes

  return debouncedValue
}
