import type { RenderPoster } from '@remotion/player';
import React, { useCallback } from 'react';
import { AbsoluteFill, Img, staticFile } from 'remotion';


interface RenderPosterComponentProps {
    height: number;
    width: number;
    thumbnailImage: string; // Adjust the type as per your actual data type
    thumbnailMessage: string; // Adjust the type as per your actual data type
  }

const RenderPosterComponentTwo: React.FC <RenderPosterComponentProps> = ({height, width, thumbnailImage, thumbnailMessage}:any) => {
    // console.log('RenderPoster')
         return (
        <AbsoluteFill>
        <Img
        src={thumbnailImage || 'https://d2tqfxp1334laf.cloudfront.net/image_projects/da764da2-2982-49be-88bd-eca414c4f5d7/assets/images/1bd6c507-015d-444e-abb8-437ed44f46f6/image.jpg'}
        width={width}
        height={height}
        />
        <div
        style={{
            position: 'absolute',
            top:'50%',
            left:'50%',
            transform: 'translate(-50%, -50%) rotate(5deg)',
            padding:'10px',
            border: '10px solid',
           borderImageSlice:'1',
           borderImageSource:'linear-gradient(90deg, #6a39ef 0%, #7734bd 100%)',
           borderRadius:'2px',
           background:'white'
        }}
        >
<div
style={{
    color:'black',
    fontSize:'24px',
    fontWeight:'bold',
    textAlign:'center',
    padding:'10px'
    // background:'black'
}}
>
{thumbnailMessage}
</div>
        </div>
      
        </AbsoluteFill>
      );
};

export default RenderPosterComponentTwo;
