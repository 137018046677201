import { Player, Thumbnail } from '@remotion/player'
import React, { useCallback, useEffect } from 'react'
import { MyCompositionHtml } from './myCompositionHtml'
import RenderPosterComponent from './renderPoster'
import { renderStill } from '@remotion/renderer'
// import { MyComp } from '@src/remotionRoot'

type gifProps = {
  htmlData: any
  duration: number
  jsonData: any
  transitionDurationInFrames: number
  transitionAnimationProp: any
  isDownload: boolean
  scriptDetails: any
  burstImagesTransitionAnimationArray: any
  videoStyle: string
}

// Assuming you want to use the Remotion Player for previewing the video
export const VideoPlayer = ({
  htmlData,
  duration,
  jsonData,
  transitionDurationInFrames,
  transitionAnimationProp,
  isDownload,
  scriptDetails,
  burstImagesTransitionAnimationArray,
  videoStyle,
}: gifProps) => {
  const videoPlayerWidth = isDownload ? jsonData.width : Math.round(jsonData.width / 1)
  const videoPlayerHeight = isDownload ? jsonData.height : Math.round(jsonData.height / 1)

  // using memo so that the video player does not rerender again and again
  const memoizedInputProps = React.useMemo(
    () => ({
      htmlData,
      jsonData: jsonData,
      duration,
      width: videoPlayerWidth, // Use the scaled values but keep the names as expected
      height: videoPlayerHeight,
      videoPlayerWidth,
      videoPlayerHeight,
      transitionDurationInFrames,
      transitionAnimationProp,
      isDownload,
      scriptDetails,
      burstImagesTransitionAnimationArray,
      videoStyle,
    }),
    [
      htmlData,
      jsonData,
      scriptDetails,
      duration,
      videoPlayerWidth,
      videoPlayerHeight,
      transitionDurationInFrames,
      transitionAnimationProp,
      isDownload,
      burstImagesTransitionAnimationArray,
      videoStyle,
    ]
  )

  // const renderPoster = useCallback(RenderPosterComponent, []);
  console.log(scriptDetails)
  const renderPoster = useCallback(
    (props:any) => <RenderPosterComponent {...props} thumbnailImage={scriptDetails.thumbnailImage || localStorage.getItem('thumbnailImage')} thumbnailMessage={scriptDetails.data[0].voiceOver}/>,
    []
  );

  // pass the memoizedInputProps in the inputProps, so it can be passed to MyCompositionHtml component
  
//   useEffect(()=>{
//       async function renderThumbnail(){
//         await renderStill({
//           renderPoster,
//           // RenderPosterComponent,
//           serveUrl: 'videoRender',
//           output: "/tmp/still.png",
//           inputProps: {
//             thumbnailImag: scriptDetails.thumbnailImage,
//             thumbnailMessage: scriptDetails.data[0].voiceOver
//           },
//         });
// }
//   },[])

  return (
    <Player
      fps={30}
      durationInFrames={duration <= 0 ? 400 : duration}
      component={MyCompositionHtml}
      autoPlay={false}
      inputProps={memoizedInputProps}
      controls={true} // Show player controls
      compositionWidth={videoPlayerWidth} // Add composition width here
      compositionHeight={videoPlayerHeight} // Add composition height here
      numberOfSharedAudioTags={10}
      // renderPoster={renderPoster}
      // showPosterWhenUnplayed
    />

  )
}
