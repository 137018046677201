import { EditOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../../../../src/context/globalContext";
import { capitalizeWords } from "../../../../../src/shared/utils/core";
import { Modal, Row, Spin } from "antd";
import { getVideoPreview } from "polotno/utils/video";
import React, { useEffect, useState } from "react";
import { PageOperationsRow } from "./pageOperationsRow";
import { VideoPanel } from "../../../../../src/features/polotno/component/customPolotno/customVideoSearchPanel";
// import React from "react";

interface Props {
  store: any;
  pageID: any;
  index: number;
  page: any;
  searchKey: string;
}

const fetchData = async (
  store: any,
  pageID: any,
  setVisualOverlay: any,
  setPreview: any
) => {
  try {
    const children = store.pages.filter((page: any) => page.id === pageID)[0]
      ?.children;
    const videoElements = children?.filter(
      (child: any) => child.type === "video"
    );
    if (videoElements?.length > 0) {
      const largestVideo = videoElements.reduce(
        (prev: any, current: any) =>
          prev.width * prev.height > current.width * current.height
            ? prev
            : current,
        videoElements[0]
      );

      // Set visual overlay
      setVisualOverlay(largestVideo);

      // Attempt to set video preview
      let videoPreview = "";
      while (!videoPreview) {
        try {
          videoPreview = await getVideoPreview(largestVideo.src);
          setPreview(videoPreview);
        } catch (error) {
          console.error("Error loading video preview:", error);
          // Optionally, set a default preview here if necessary
        }
      }
    } else {
      const imageElements = children?.filter(
        (child: any) => child.type === "image"
      );
      const largestImage = imageElements?.reduce(
        (prev: any, current: any) =>
          prev.width * prev.height > current.width * current.height
            ? prev
            : current,
        imageElements[0]
      );

      // Set visual overlay
      setVisualOverlay(largestImage);

      // Set image preview
      setPreview(largestImage?.src);
    }
  } catch (e) {
    console.log(e);

    // Optionally, handle any other errors here
  }
};

 const FootageSelection = ({
  store,
  pageID,
  index,
  page,
  searchKey,
}: Props) => {
  const [preview, setPreview] = useState<string>("");
  const [visualOverlay, setVisualOverlay] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [searchQueries, setSearchQueries] = useState<any>([]);

  useEffect(() => {
    if (!visible) {
      fetchData(store, pageID, setVisualOverlay, setPreview);
      const searchQuery = store?.pages[index]?.custom?.searchQuery;
      const searchQueries = store?.pages[index]?.custom?.searchQueries;
      if (searchQuery)
        setSearchQuery(capitalizeWords(store.pages[index].custom.searchQuery));
      if (searchQueries) setSearchQueries([searchKey]);
    }
  }, [visible]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData(store, pageID, setVisualOverlay, setPreview);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Row gutter={[0, 8]} style={{ flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontFamily: "DM Sans",
            fontSize: "14px",
            flexDirection: "row",
          }}
        >
          {/* <span style={{ maxWidth: '70%' }}>
            {searchQuery ? 'Scene - ' + (index + 1) + ' : ' + searchQuery : 'Scene - ' + (index + 1)}
          </span> */}

          <EditOutlined
            style={{
              color: "var(--color-primary-600)",
              marginLeft: "8px",
              marginRight: "5px",
              fontSize: "16px",
            }}
            title="Change Photo"
            onClick={() => {
              setVisible(true);
            }}
          />
        </div>
        {preview ? (
 <img
 src={preview}
 alt=''
 style={{
   width: '40px',
   height: '40px',
   display: 'block',
   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
   borderRadius: '8px',
   cursor: 'pointer',
 }}
/>

        ):(
          <Spin/>
        )}
       
      </div>
      <>
        <SelectImageModal
          visible={visible}
          setVisible={setVisible}
          store={store}
          visualOverlayID={visualOverlay?.id}
          index={index}
          searchQueries={searchQueries}
          searchQuery={searchQuery}
        />
      </>
    </Row>
  );
};

interface ModalProps {
  store: any;
  visualOverlayID: any;
  visible: boolean;
  setVisible: any;
  index: number;
  searchQueries: string[];
  searchQuery:string
}

const SelectImageModal = ({
  visible,
  setVisible,
  store,
  visualOverlayID,
  index,
  searchQueries,
  searchQuery
}: ModalProps) => {
  // console.log(searchQueries)
  return (
    <Modal
      title="Change Media"
      open={visible}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}
    >
      <VideoPanel
        store={store}
        elementID={visualOverlayID}
        index={index}
        isNotPanel={true}
        searchQueries={searchQueries}
        // displaysearchQuery={searchQuery}
      />
    </Modal>
  );
};

interface ModalProps {
  store: any;
}


export default FootageSelection