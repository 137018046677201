import { PEXELS_KEY, NODE_BACKEND_URL } from '../../../../config'
import axios, {AxiosRequestConfig} from 'axios'
import { convertImageUrlToJpg } from '../../imageConvertion/pngTojpg'
import { generateUniqueId } from '../../core'
import sharp from "sharp";
import fs from "fs";
import { getDomainID, qaBackendApiInstance } from '../../../../config/config';
import { message } from 'antd';
export async function getPexelsVideos(searchQuery: string) {
  const res = await axios
    .get('https://api.pexels.com/videos/search', {
      headers: {
        Authorization: PEXELS_KEY,
      },
      params: {
        query: searchQuery,
        orientation: 'landscape',
        size: 'medium',
      },
    })
    .then((response) => {
      const videoResult = response?.data?.videos[0]
      if (!videoResult) {
        return -1
      }
      const videos = videoResult.video_files.filter((file: { quality: string }) => file.quality === 'sd')
      const videoUrl = videos[videos.length - 1].link
      return videoUrl
    })
    .catch((error) => {
      console.log(error)
      return -1
    })

  return res
}

export async function getGettyVideos(searchQuery: string, orientations: string) {
  const res = await axios
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/videoSearch`, {
      params: {
        searchQuery: searchQuery,
        isFirstRequest: false,
        orientations: orientations,
      },
    })
    .then( async (response) => {
      const videoResult = response?.data?.videos[0]
      if (!videoResult) {
        return -1
      }
      const videos = videoResult.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'comp')
      const videoUrl = videos.uri
      // console.log(videoUrl)
      // const videoUrl = 'https://delivery.gettyimages.com/downloads/2012532915?k=20&e=H0bqKmuQadraqsoxLdIP8aq6_cGQHjP6kFGAV_39IexChCj9I1x0Afzbg_nHrrGeBKrzndtltul2pieopPnNUTmLKRtJHAQIk_GJARuZUNh46WkYjd3Fzlb4LVG1zNam' 
      // const hdUri= await GettyVideoDownloadSizes(videoResult.id);
      // console.log(hdUri)
      // if(hdUri){
      //   return hdUri
      // }
      return videoUrl
     
    })
    .catch((error) => {
      console.log(error)
      return -1
    })
  return res
}


export async function GettyVideoDownloadSizes(id: string): Promise<any | undefined> {
  const res = await axios
  .get(`${NODE_BACKEND_URL}api/v1/gettyImage/Video-download-sizes`, {
    params:{
      assetId: id,
      size: 'hd16'
    },
  })
  .then((response)=>{
    const uri=response.data || ""
    if (uri){
      return uri
    }
   
    return undefined
  })
  return res
}

export async function getGettyImages(searchQuery: string, orientations: string, isFirstRequest?: boolean) {
  const graphicalStyle = localStorage.getItem('graphicalStyle')
  const res = await axios
  
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/imageSearch`, {
      params: {
        searchQuery: searchQuery,
        // isFirstRequest: isFirstRequest ? isFirstRequest : true,
        isFirstRequest: false,
        orientations: orientations,
        graphicalStyle: graphicalStyle || ''
      },
    })
    .then(async(response) => {
      const resultLength=response?.data?.length> 5 ? 5 : response?.data?.length
      const randomIndex = Math.floor(Math.random() * resultLength) || 0
      const imagesResult = response?.data[randomIndex]
      if (!imagesResult) {
        return -1
      }
      const images = imagesResult.display_sizes.find(
        (displaySize: { name: string }) => displaySize.name === 'mid_res_comp'
      ) // chnage
      const imageUrl = images.uri
      // const hdUri : string | undefined = await GettyImageDownloadSizes(imagesResult.id);
      // if(hdUri){
      //   return hdUri
      // }
      return imageUrl
    })
    .catch((error) => {
      console.log(error)
      return -1
    })
  return res
}


export async function GettyImageDownloadSizes(id: string): Promise<any | undefined> {
  const res = await axios
  .get(`${NODE_BACKEND_URL}api/v1/gettyImage/Image-download-sizes`, {
    params:{
      assetId: id
    },
  })
  .then((response)=>{
    console.log(response?.data?.uri) 
    const uri=response?.data?.uri || ""
    if (uri){
      return uri
    }
    return undefined
  })
}


export async function getGettyVideosTest(searchQuery: string, orientations: string) {
  const res = await axios
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/videoSearch`, {
      params: {
        searchQuery: searchQuery,
        isFirstRequest: false,
        orientations: orientations,
      },
    })
    .then( async (response) => {
      const resultLength=response?.data?.videos?.length> 5 ? 5 : response?.data?.videos?.length
      const randomIndex = Math.floor(Math.random() * resultLength) || 0
      const videoResult = response?.data?.videos[randomIndex]
      if (!videoResult) {
        return -1
      }
      const videos = videoResult.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'comp')
      const videoUrl = videos.uri
      // const videoUrl = 'https://delivery.gettyimages.com/downloads/2012532915?k=20&e=H0bqKmuQadraqsoxLdIP8aq6_cGQHjP6kFGAV_39IexChCj9I1x0Afzbg_nHrrGeBKrzndtltul2pieopPnNUTmLKRtJHAQIk_GJARuZUNh46WkYjd3Fzlb4LVG1zNam' 
      // const hdUri= await GettyVideoDownloadSizes(videoResult.id);
      // console.log(hdUri)
      // if(hdUri){
      //   return hdUri
      // }
      const obj = {uri:videoUrl, id: videoResult.id}
      return obj
     
    })
    .catch((error) => {
      console.log(error)
      return -1
    })
  return res
}

export async function aiImagesAPI(searchQuery: string, orientations: string){

  const formData = new FormData();
  formData.append('prompt', searchQuery);
  formData.append('n_images', '1');
  // formData.append('image_name', searchQuery.slice(0,6).replace(/\s+/g, ''))
  formData.append('image_name', generateUniqueId())
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  try{
    const res = await axios
    .post(`https://imgbd.virtualshoots.ai/api/sd_focus_animated`, formData, config)
    console.log('Response:', res.data);
    const result=res.data.background_image
    console.log(result)
    if(!result){
      try{
        await axios.get('https://imgbd.virtualshoots.ai/api/clear-gpu')

      }catch(error){
        return -1
      }
      return -1
    }
    try {
 
      return result
      
  } catch (error) {
      console.error('Error converting image:', error);
      try{
        await axios.get('https://imgbd.virtualshoots.ai/api/clear-gpu')

      }catch(error){
        return -1
      }

  }
    // return result
  
  }catch(error){
    console.error('Error:', error);
    try{
        await axios.get('https://imgbd.virtualshoots.ai/api/clear-gpu')

      }catch(error){
        return -1
      }

  }
 


}
export async function generateDalleImage(searchQuery: string, orientations:string) {
  const size = orientations === 'vertical' ? '1024x1792' : '1792x1024' 
  
  const postBody = {
    prompt: searchQuery,
    size:size
  }
  console.log(postBody)
  const localEndPoint = axios.create({
    baseURL: `${NODE_BACKEND_URL}`,
  })
  try{
    const response = await localEndPoint.post('/api/v1/openAi/dalle-image', postBody)
    // console.log(response.data)
    const params={
        image_url : response.data,
        domain_id : getDomainID()
    }
    console.log(response.data)
    const token = localStorage.getItem('accessToken')
    const uploadAsset = await qaBackendApiInstance.post(
      `/asset/upload-from-url`,
      params,
      {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    )
    console.log(uploadAsset.data.image_url)
    return uploadAsset.data.image_url

  }catch(error:any){
    
    return undefined
  }
  
 
}

// export async function aiImagesAPI(searchQuery: string, orientations:string) {
//   const size = orientations === 'vertical' ? '1024x1792' : '1792x1024' 
  
//   const postBody = {
//     prompt: searchQuery,
//     size:size
//   }
//   const localEndPoint = axios.create({
//     baseURL: `${NODE_BACKEND_URL}`,
//   })
//   try{
//     const response = await localEndPoint.post('/api/v1/openAi/dalle-image', postBody)
//     // console.log(response.data)
//     const params={
//         image_url : response.data,
//         domain_id : getDomainID()
//     }
  
//     const token = localStorage.getItem('accessToken')
//     const uploadAsset = await qaBackendApiInstance.post(
//       `/asset/upload-from-url`,
//       params,
//       {
//         headers: {
//             'Authorization': `Bearer ${token}`
//         }
//     }
//     )
//     console.log(uploadAsset.data.image_url)
//     return uploadAsset.data.image_url

//   }catch(error:any){
    
//     return undefined
//   }
  
 
// }

export async function voiceCloneAPI(name:any, descripton:any, files:any){
  const formData = new FormData();
    formData.append('name', name);
    formData.append('description', '');
    // formData.append('labels', JSON.stringify(labels));

    files.forEach((file:any, index:any) => {
      formData.append(`file${index}`, file);
    });
  const localEndPoint = axios.create({
    baseURL: `${NODE_BACKEND_URL}`,
  })

  try{
    const response = await localEndPoint.post('/api/v1/elevenlabs/voice-clone',
      formData
    )
    console.log(response.data)
    return response.data
  }catch(error){
    console.log(error)
  }
}

export async function creditsDeduct(type:string, credits:number){
  const domainID = getDomainID()
  const token = localStorage.getItem('accessToken')

try{
const response = await qaBackendApiInstance.post(`/user/credits?domain_id=${domainID}&type=${type}`,
  {
    headers: {
        'Authorization': `Bearer ${token}`
    }
}

)
return response.data
}catch(error){
  return -1
}
}

export async function getUserDetails(){
  const domainID = getDomainID()
  const token = localStorage.getItem('accessToken')
  try{
const response = await qaBackendApiInstance.get(`/user/details`,
  {
    headers: {
        'Authorization': `Bearer ${token}`
    }
}
)
  return response.data
  }catch(error){
  return -1
  }
}

