import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { NODE_BACKEND_URL } from '../../config'
import { useState } from 'react'

export const useGetGettyImageVideos = (query = '', initialAspectRatio: string, orientation: string) => {
  const [aspectRatio, setAspectRatio] = useState(initialAspectRatio)
  const queryClient = useQueryClient()

  const fetchVideos = async (aspectRatio: string) => {
    const response = await axios.get(`${NODE_BACKEND_URL}api/v1/gettyImage/videoSearch`, {
      params: {
        searchQuery: query,
        // aspectRatio: aspectRatio,
        isFirstRequest: false,
        orientations: orientation,
        relatedSearch: true
      },
    })
    console.log(response)
    return response.data
  }

  return useQuery(['fetchGettyImageVideos', query, aspectRatio, orientation], () => fetchVideos(aspectRatio), {
    enabled: !!query,
    onSuccess: (data) => {
      if (data.videos.length === 0 && aspectRatio !== '16:9') {
        const newAspectRatio = '16:9'
        setAspectRatio(newAspectRatio)

        // Invalidate queries only if the aspect ratio has been changed
        queryClient.invalidateQueries(['fetchGettyImageVideos', query, newAspectRatio, orientation])
      }
    },
  })
}
