import { Select, Input, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useGlobalContext } from './../../../../context/globalContext';
import { CheckSquareOutlined} from '@ant-design/icons'
interface captionEditProps {
  store: any;
  words: any;
  index: number;
}

const EditCaptions: FC<captionEditProps> = ({ store, words, index }) => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
  } = useGlobalContext();
  const [oldConcatenatedString, setOldCConcatenatedString]=useState('')
  const [wordLength, setWordLength]=useState(0)
  const [newString, setNewString]=useState('')
 
    const captionSave=async()=>{

    const newStringLength=newString.split(' ')
        if(wordLength === newStringLength.length){
            const updatedWords = replaceWords(words, newStringLength);
            // console.log(updatedWords)
            await setScriptDetails((prevScript: { data: any }) => {
                const updatedScript = { ...prevScript }
                const updatedData = [...prevScript.data ? prevScript.data : prevScript]
                updatedData[index] = {
                  ...updatedData[index],
                  captions: words ? [{words:updatedWords, text:newString}] : [],
                }
                message.success("Captions updated!")
                return { ...updatedScript, data: updatedData }
              })
        } else{
            message.warning("Ensure that the length of the previous caption matches the length of the new caption.")
        }
    }

    function replaceWords(words: any, replacements: any) {
        return words.map((wordObj:any, index:number) => ({
            ...wordObj,
            word: replacements[index]
        }));
    }
//   const concatenatedString = words.map((item: any) => item.word).join(' ');
useEffect(()=>{
    setOldCConcatenatedString(words.map((item: any) => item.word).join(' '))
    setNewString(words.map((item: any) => item.word).join(' '))
    setWordLength(words.length)
},[])
  return (
    <>
      <Label>Scene {index + 1}</Label>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Input
          value={newString}
          placeholder='Edit captions'
          style={{
            width: '100%',
            marginRight: '10px',
          }}
          onChange={(e)=> setNewString(e.target.value)}
        />
        <CheckSquareOutlined 
        style={{
            color: 'var(--color-primary-600)',
            background: 'white',
            cursor:'pointer',
            fontSize: '20px',
        }}
        onClick={captionSave}
        />
      </div>
    </>
  );
};

const Label = ({ children }: { children: React.ReactNode }) => {
  return (
    <h3
      style={{
        color: 'black',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'DM Sans',
        letterSpacing: 'normal',
        marginBottom: '10px',
      }}
    >
      {children}
    </h3>
  );
};
export default EditCaptions;
