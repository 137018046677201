import { LoadingOutlined, DownCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, UpCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { getAudioDurationInSeconds } from '@remotion/media-utils'
import { useGetVoices } from '../../api/elevenLabs/getVoices'
import { categorisedMusicList, whisperAiVoiceList } from '../../data/music'
import { Button, InputNumberProps, Row, Col, Select, Slider, SliderSingleProps, Switch, Tag, message, Upload, Input } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useGlobalContext } from '../../context/globalContext'
import { AiVoiceRow } from '../video/customVideoTimeline/cardRows/aiVoiceRow'
import './audioPanel.less'
import { generateUniqueId } from '../../shared/utils/core'
import { aiVoicesResponse } from '../../data/aiVoiceResponse'
import { formatDuration } from '../../shared/utils/convertSecs'
import { prefetchAsset } from '../../shared/utils/remotion'
import { IS_FREE_PLATFORM } from '../../config'
import { getDomainID, getToken } from '../../config/config'
import { aiTextToSpeech, generateCaptions, getPrevNextText } from '../../shared/utils/VideoGeneration/audioGeneration'
import { TranslateAudio } from '../translate/translateAudio'
import { creditsDeduct, getUserDetails, voiceCloneAPI } from '@src/shared/utils/VideoGeneration/helperFunctions/mediaSearch'
import { warning } from '@remix-run/router/dist/history'
import { deleteClonedVoice, getClonedVoices, saveClonedVoices } from '@src/shared/utils/VideoGeneration/helperFunctions/voiceCloneApi'
const { Option } = Select

interface AudioSidebarProps {
  store: any
}

interface MusicDetails {
  30: { PAS?: string[] }
}

interface ClonedVoiceData {
  user_id: string;
  voice_name: string | null;
  createdAt: string;
  id: string;
  voice_id: string | null;
  updatedAt: string | null;
}

const labelTypeColors: { [key: string]: string } = {
  accent: 'magenta',
  description: 'green',
  age: 'cyan',
  gender: 'volcano',
  'use case': 'blue',
}
export const AudioSidebar = ({ store }: AudioSidebarProps) => {
  const {
    BackgroundMusic: [backgroundMusic, setBackgroundMusic],
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [scriptDetails, setScriptDetails],
    AudioFade:[audioFade, setAudioFade],
    VideoStyle: [videoStyle, setVideoStyle],
    CurrentUserData: [currentUserData, setCurrentUserData]
  } = useGlobalContext()

  const [selectedIndustryName, setSelectedIndustryName] = useState(`${scriptDetails?.backgroundMusic?.industry}`)
  const [selectedMusicUrl, setSelectedMusicUrl] = useState(`${scriptDetails?.backgroundMusic?.url}`)
  const [selectedMusicName, setSelectedMusicName] = useState(`${scriptDetails?.backgroundMusic?.name}`)
  const [playingUrl] = useState('')
  const [showVoiceOver, setShowVoiceOver] = useState(false)
  const [selectedVoiceName, setSelectedVoiceName] = useState(`${scriptDetails.data[0].name || 'Custom'}`)
  const [backgroundVolume, setBackgroundVolume] = useState(
    (scriptDetails?.backgroundMusic?.backgroundVolume ?? 0.5) * 100
  )
  const [aiVoiceVolume, setAiVoiceVolume] = useState((scriptDetails?.aiVoiceVolume ?? 1) * 100)
  const [aiVoiceSpeed, setAiVoiceSpeed] = useState(scriptDetails?.aiVoiceSpeed ?? 1)
  const [voiceChanging, setVoiceChanging] = useState(false)
  const [translateloading, setTranslateloading] = useState(false)
  const [fileList, setFileList] = useState([]);
  const [IsCloning, setIsCloning] = useState(false)
  const [isSizeExceeded, setIsSizeExceeded] = useState(false)
  const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value: any) => `${value}%`
  const [voiceName, setVoiceName] = useState('')
  const [ClonedVoiceData, setClonedVoiceData] = useState<ClonedVoiceData[]>([]);
  
  const [customVoiceCloning, setCustomVoiceCloning] = useState(false)
  // const speedformatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value: any) => `${value}x`
  const speedformatter = (value:any) => `${value}x`;
  // Ref to the audio player
  // const audioRef = React.createRef<HTMLAudioElement>()
  const audioRef = useRef<HTMLAudioElement>(null)

  const [currentPlayingTrack, setCurrentPlayingTrack] = useState<string | null>(null)
  const { data: aiVoice, isLoading, isSuccess } = useGetVoices()

  const handlePlayAudio = (previewUrl: string) => {
    if (audioRef.current) {
      audioRef.current.src = previewUrl

      audioRef.current.addEventListener('loadedmetadata', () => {
        // Log duration
        console.log('Duration:', formatDuration(Math.floor(audioRef?.current?.duration || 1)))
      })
      audioRef.current.play().catch((e) => console.error('Playback was interrupted:', e))
      setCurrentPlayingTrack(previewUrl)
    }
  }

  // Pause audio handler
  const handlePauseAudio = () => {
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause()
      setCurrentPlayingTrack(null)
    }
  }

  const togglePlayPause = (value: string) => {
    const [industry, musicUrl, name] = value.split('@@@')

    if (currentPlayingTrack === musicUrl) {
      handlePauseAudio()
    } else {
      handlePlayAudio(musicUrl)
    }
  }

  const onVoiceOverVolumeChange = async (newValue: number) => {
    setAiVoiceVolume(newValue)
    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      return { ...updatedScript, aiVoiceVolume: newValue / 100 }
    })
  }

  const onVoiceSpeedChange = async (newValue: number) => {
    setAiVoiceSpeed(newValue)
    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      return { ...updatedScript, aiVoiceSpeed: newValue}
    })
  }

  const onBackgroundVolumeChange = async (newValue: number) => {
    setBackgroundVolume(newValue as number)

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript: any = { ...prevScript }

      return {
        ...updatedScript,
        backgroundMusic: { ...updatedScript.backgroundMusic, backgroundVolume: newValue / 100 },
      }
    })
  }
  const handleFadeInOut = (checked:any, event:any) => {
    // setAudioFade(checked)
    setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }
  
      return { ...updatedScript, audioFade:checked }
    })
  }
  const handleBackgroundMusicChange = async (value: string) => {
    const [industryName, musicUrl, name] = value.split('@@@')

    if (currentPlayingTrack === musicUrl) {
      handlePauseAudio()
    }

    setSelectedIndustryName(industryName)
    setSelectedMusicUrl(musicUrl)
    setSelectedMusicName(name)

    const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
    // setBackgroundMusic({ url: musicUrl, duration: backgroundAudioDuration, industry: industryName, name })

    const backgroundScript = {
      url: musicUrl,
      duration: backgroundAudioDuration,
      industry: industryName,
      name: name,
      backgroundVolume: backgroundVolume / 100,
    }

    // const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
    setBackgroundMusic(backgroundScript)

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      return { ...updatedScript, backgroundMusic: backgroundScript }
    })
  }
  const aiVoiceDropDown = [
    {
      label: 'AI Voices',
      options: whisperAiVoiceList.map((aiVoice) => ({
        label: <span>{aiVoice.label}</span>,
        value: aiVoice.value,
      })),
    },
   
  ]
  if(currentUserData?.subscription_plan !== 'free' || currentUserData?.subscription_plan !== 'quickads_tier2' || currentUserData?.subscription_plan !== 'quickads_tier1'){
    // console.log(aiVoicesResponse)
    aiVoiceDropDown.push({
      label: 'Premium AI Voices',
      options: aiVoice?.voices
        ?.filter((voice: any) => voice.category !== 'cloned') // Filter voices based on category
        .map((voice: any) => ({
          label: (
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{voice.name}</span>
                <Button
                  type='text'
                  icon={<PlayCircleOutlined />}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the select dropdown from closing
                    handlePlayAudio(voice.preview_url);
                  }}
                />
              </div>
              <div className='tag-container'>
                {Object.entries(voice.labels).map(([key, value]) => (
                  <Tag color={labelTypeColors[key] || 'default'} key={key}>
                    {`${value}`}
                  </Tag>
                ))}
              </div>
            </div>
          ),
          value: voice.voice_id, // Value for each option
        })),
    });
    

  }
  const playVoiceSample = async (
    text: string,
    aiVoiceName: string,
    isElevenApi: boolean,
    elevenlabsVoiceId: string | undefined,
    index: number
  ): Promise<any> => {
    
    try {
      videoStyle !== 'Burst' && store.selectPage(store.pages[index].id)
      let domainId
      let token

      if (IS_FREE_PLATFORM === 'false') {
        domainId = getDomainID()
        token = getToken()
      }
      const Prevnext=getPrevNextText(scriptDetails.data, index)
      const audioUrl = await aiTextToSpeech(text, elevenlabsVoiceId, domainId, aiVoiceName, isElevenApi, token, Prevnext.prevText, Prevnext.nextText)
      // const audioUrl = await aiTextToSpeech(text, elevenlabsVoiceId, domainId, aiVoiceName, isElevenApi, token, '', '')

      const duration = await getAudioDurationInSeconds(audioUrl) // Assuming `src` is the URL to your audio file

      let newDuration
      if (videoStyle !== 'Burst') {
        newDuration =
          duration * 1000 > store.pages[index].duration ? duration * 1000 + 500 : store.pages[index].duration + 500

        if (index === store.pages.length - 1) {
          newDuration = newDuration + 1000
        }

        store.activePage.set({ duration: newDuration })
      }

      let captionsResult: any
      // await generateCaptions(audioUrl)
      try {
        captionsResult = await generateCaptions(audioUrl, userDetails?.purpose?.language || '')
      } catch (e) {
        message.info('Could not generate captions for the audio')
      }
    
      await setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        const updatedData = [...prevScript.data]

        updatedData[index] = {
          ...updatedData[index],
          name: aiVoiceName,
          elevenLabsAiVoiceId: elevenlabsVoiceId,
          url: audioUrl,
          isElevenApi: isElevenApi,
          // aiVoiceVolume: voiceOverVolume / 100,
          sounds: [{ url: audioUrl, audioDuration: duration }],
          captions: [{ text: text, words: captionsResult?.data?.words }],
        }

        return { ...updatedScript, data: updatedData }
      })

      if (videoStyle !== 'Burst') {
        store.pages[index].set({
          duration: newDuration,
          custom: {
            ...store.pages[index].custom,
            // aiVoiceVolume: voiceOverVolume / 100,
            sounds: [{ url: audioUrl, audioDuration: duration }],
            captions: [{ text: text, words: captionsResult?.data?.words }],
          },
        })
      }

    

      await prefetchAsset(audioUrl)
      setSelectedVoiceName(aiVoiceName)
      return { audioUrl, captionsResult, duration }

    } catch (error) {
      console.error('Error making text-to-speech request:', error)
    
    }
  }
  
  const handleChangeVoice= async (value: string)=>{

    const getCredits = await getUserDetails();
    setVoiceChanging(true)

    if (getCredits == -1) {
      message.warning('Something went wrong!');
      setVoiceChanging(false)
      return;
    }
    if (getCredits?.voice_limit < 1) {
      message.warning(
        'Voice credits have been exhausted. Please upgrade the plan to use the voice.'
      );
      setVoiceChanging(false)
    
      return;
    }
    setVoiceChanging(true)
    // console.log(scriptDetails)
    
    let totalDurationInSeconds=0
    const voice = aiVoice?.voices?.find((voice: any) => voice.voice_id === value)
    for (let index = 0; index < scriptDetails.data.length; index++) {
      const script = scriptDetails.data[index]
      const result = await playVoiceSample(
        script.voiceOver,
        voice ? voice.name : value,
        voice ? true : false,
        voice ? value : undefined,
        index
      )
      totalDurationInSeconds=totalDurationInSeconds+result.duration
    }
    setVoiceChanging(false)
    console.log(totalDurationInSeconds)
    const audioDurationInMins = Math.ceil(totalDurationInSeconds / 60)
    creditsDeduct('voice_credits', audioDurationInMins)
  }

  const handleCloneVoiceChange = async(value: string)=>{
    const getCredits = await getUserDetails();
    setCustomVoiceCloning(true)

    if (getCredits == -1) {
      message.warning('Something went wrong!');
      setCustomVoiceCloning(false)
      return;
    }
    if (getCredits?.voice_limit < 1) {
      message.warning(
        'Voice credits have been exhausted. Please upgrade the plan to use the voice.'
      );
      setCustomVoiceCloning(false)
    
      return;
    }
 
    let totalDurationInSeconds=0
    setCustomVoiceCloning(true)
    const voiceData = ClonedVoiceData?.find(voice => voice.id === value)
    for (let index = 0; index < scriptDetails.data.length; index++) {
      const script = scriptDetails.data[index]
      const result = await playVoiceSample(
        script.voiceOver,
        voiceData?.voice_name || '',
        true,
        voiceData?.voice_id || '',
        index
      )
      totalDurationInSeconds=totalDurationInSeconds+result.duration
    }
    setCustomVoiceCloning(false)
    
    const audioDurationInMins = Math.ceil(totalDurationInSeconds / 60)
    creditsDeduct('voice_credits', audioDurationInMins)
  }
  // api calls

  const handleShowVoiceOver = () => {
    setShowVoiceOver(!showVoiceOver)
  }

  // const { uniqueURLs, repeatedURLs } = segregatePASURLs(categorisedMusicList)

  const categories = Object.keys(categorisedMusicList)
  const options: any = {}

  categories.map((category) => {
    const musicList = categorisedMusicList[category][60]['PAS']
    musicList.map((musicUrl: any) => {
      const musicKey = `${category}@@@${musicUrl.url}@@@${musicUrl.name}`
      const option = {
        label: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ flex: 1 }}>{musicUrl.name}</div>
            <div style={{ flex: '0 0 auto', marginLeft: '10px', marginRight: '2px' }}>
              {' '}
              {/* Added marginLeft */}
              {musicUrl.duration}
            </div>
            <Button
              type='text'
              icon={currentPlayingTrack === musicUrl?.url ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
              onClick={(e) => {
                e.stopPropagation()
                togglePlayPause(musicKey)
              }}
            />
          </div>
        ),
        value: musicKey,
      }
      if (options[category]) {
        options[category].push(option)
      } else {
        options[category] = [option]
      }
    })
  })
  const optionValues = Object.keys(options).map((option: any) => {
    return { label: option, options: options[option] }
  })
  
  const handleFilesAudioChange = ({ fileList }:any) => {
    if(!isSizeExceeded) setFileList(fileList)
    
  }

  const handleUpload = async () => {
    const getCredits = await getUserDetails()
    if(getCredits == -1){
      message.warning('Something went wrong!')
      return
    }
    if(getCredits?.voice_clones_limit < 1){
      message.warning('Voice clone credits have been exhausted. Please upgrade the plan to clone the voice.')
      return
    }
  
    if(voiceName.length<1){
      message.warning('Pleae enter voice name.')
      return
    }
    setIsCloning(true)
    try {
      const fileData: any =[]
      fileList.forEach((file:any) => {
      fileData.push(file.originFileObj) 
      // Ensure filename is included
    });
    message.info('Cloning will take a few moments. Please wait.')
    const cloneVoiceId = await voiceCloneAPI(voiceName,'', fileData)
    for (let index = 0; index < scriptDetails.data.length; index++) {
      const script = scriptDetails.data[index]
      const result = await playVoiceSample(
        script.voiceOver,
        voiceName,
        true,
        cloneVoiceId,
        index
      )
    }
    setSelectedVoiceName(voiceName)
    message.success('Voice cloned successfully.')
    try{
      await saveClonedVoices(voiceName, cloneVoiceId)
      await creditsDeduct('voice_clones_credits', 1)
    }catch(error){
      message.error('Something went wrong!')
    }
    } catch (error) {
      message.error('Upload failed.');
      console.error(error);
    }finally{setIsCloning(false)}
  };

  const audioFilebeforeUpload = (file:any) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    console.log(file)
    if (!isLt2M) {
      message.error('File must be smaller than 2MB!');
      setIsSizeExceeded(true)
      return false
    }
    // console.log(isLt2M)
    // return isLt2M;
    setIsSizeExceeded(false)
    return false

  };

  useEffect(()=>{
    async function clonedVoices(){
      try{
        const clonedVoices = await getClonedVoices()
        setClonedVoiceData(clonedVoices)
      }catch{
        console.log('Failed to get clones voices.')
      }
    }
    clonedVoices()
  },[])
  return (
    <>
      <Row gutter={[0, 4]} style={{ flexDirection: 'column' }}>
        <h3
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginBottom: '10px',
          }}
        >
          Background Music
        </h3>
        <Select
          style={{ minWidth: '200px', width: '100%' }}
          placeholder='Select Background Music'
          optionFilterProp='children'
          onChange={handleBackgroundMusicChange}
          value={selectedMusicName}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <audio ref={audioRef} src={playingUrl} style={{ display: 'none' }} />
            </div>
          )}
          options={optionValues}
        />
        <p
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginTop: '10px',
          }}
        >
          Background Music Volume
        </p>
        <Slider
          style={{
            width: '80%',
          }}
          min={1}
          max={100}
          onChange={onBackgroundVolumeChange}
          tooltip={{ formatter }}
          defaultValue={backgroundVolume}
          value={typeof backgroundVolume === 'number' ? backgroundVolume : 0}
        />
       <div style={{ display: 'flex', flexDirection: 'row' }}>
       <h3
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans',
              letterSpacing: 'normal',
              marginBottom: '5px',
            }}
          >
            Fade Audio In/Out
          </h3>
       <Switch size="small" style={{marginTop: '15px', marginLeft: "10px"}} onChange={handleFadeInOut} defaultChecked={scriptDetails?.audioFade || false} value={scriptDetails?.audioFade}/>
        </div> 
        <div style={{ display: 'flex', flexDirection: 'column' }}>
       <h3
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans',
              letterSpacing: 'normal',
              marginBottom: '5px',
            }}
          >
            AI Voice Speed
          </h3>
          <Slider
              style={{
                width: '80%',
              }}
              step={0.01}
              min={0.5}
              max={2}
              onChange={onVoiceSpeedChange}
              tipFormatter={speedformatter}
              tooltip={{open:true}}
              defaultValue={aiVoiceSpeed}
              value={typeof aiVoiceSpeed === 'number' ? aiVoiceSpeed : 1}
            />
        </div> 
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Row gutter={[0, 0]} >
        <Col>
        <h3
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans',
              letterSpacing: 'normal',
              marginBottom: '5px',
            }}
          >
            Translate Audio
          </h3>
          </Col>
          {translateloading && (
          <Col>
          <LoadingOutlined
              style={{
                marginLeft: '10px',
                marginTop: '15px',
                color: '#6938ef',
              }}
            />
             </Col>
        )}
        </Row>
         
         <TranslateAudio
         store={store}
         translateloading={translateloading}
         setTranslateloading={setTranslateloading}
         aiVoicesResponse={aiVoicesResponse}
         />
         
        </div> 
        <Row gutter={[0, 0]} >
        <Col>
        <h3
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginBottom: '10px',
          }}
        >
          Change Voice
        </h3>
        </Col>
        {voiceChanging && (
          <Col>
        <LoadingOutlined
              style={{
                marginLeft: '10px',
                marginTop: '15px',
                color: 'var(--color-primary-600)',
              }}
            />
             </Col>
        )}
        
        </Row>

        <Select
          style={{ minWidth: '200px', width: '100%' }}
          placeholder='Select Background Music'
          optionFilterProp='children'
          onChange={handleChangeVoice}
          value={selectedVoiceName}
          defaultValue={selectedVoiceName}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <audio ref={audioRef} src={playingUrl} style={{ display: 'none' }} />
            </div>
          )}
          options={aiVoiceDropDown}
        />

<Row gutter={[0, 0]} >
        <Col>
        <h3
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginBottom: '10px',
          }}
        >
          Cloned Voices
        </h3>
        </Col>
        {customVoiceCloning && (
          <Col>
        <LoadingOutlined
              style={{
                marginLeft: '10px',
                marginTop: '15px',
                color: 'var(--color-primary-600)',
              }}
            />
             </Col>
        )}
        </Row>
        <Select
          style={{ minWidth: '200px', width: '100%' }}
          placeholder='Select voice'
          optionFilterProp='children'
          onChange={handleCloneVoiceChange}
          value={selectedVoiceName}
          defaultValue={selectedVoiceName}
          disabled={customVoiceCloning}
        >
          {ClonedVoiceData?.map((voice:any)=>(
           <Option key={voice.id} value={voice.id}>{voice.voice_name || '' }</Option>
          ))}
          </Select>
    <Row gutter={[0, 0]} >
        <Col>
        <h3
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginBottom: '10px',
          }}
        >
          Clone Voice
        </h3>
        </Col>
       
          {/* <Col>
        <LoadingOutlined
              style={{
                marginLeft: '10px',
                marginTop: '15px',
                color: 'var(--color-primary-600)',
              }}
            />
             </Col> */}
       
        
        </Row>
        <Input
          placeholder='Enter Voice Name'
          defaultValue={voiceName}
          style={{
            width: '100%',
            marginRight: '10px',
          }}
          onChange={(e)=>setVoiceName(e.target.value)}
          // defaultValue={scriptDetails.data ? scriptDetails.data[index].voiceOver : ''}
          // onChange={(e) => {
          //   if (scriptDetails.data[index].voiceOver !== e.target.value) {
          //     scriptDetails.data[index].voiceOver = e.target.value
          //     setTextUpdated(true)
          //   }
          // }}
          className='hoverable-text-box' // Apply the CSS class here
        />
        <Upload
      multiple
      fileList={fileList}
      onChange={handleFilesAudioChange}
      beforeUpload={audioFilebeforeUpload} // Prevent automatic upload
      accept="audio/*"
      maxCount={3}
    >
      <Button icon={<UploadOutlined />}>Upload Files</Button>
    </Upload>
    {/* <input type='file' name='file' onChange={handleFilesAudioChangeTwo} multiple/> */}
    <Button
            style={{ background: 'var(--gradient-primary)', color: 'white' }}
            onClick={handleUpload}
            // loading={soundEffectLoading}
            // disabled={soundEffectLoading}
            disabled={fileList.length === 0}
            loading={IsCloning}
          >
            Clone
          </Button>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h3
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans',
              letterSpacing: 'normal',
              marginBottom: '5px',
            }}
          >
            Voice Overs
          </h3>
          {!showVoiceOver ? (
            <DownCircleOutlined
              style={{ fontSize: '16px', marginLeft: '5px', marginTop: '5px' }}
              onClick={handleShowVoiceOver}
            />
          ) : (
            <UpCircleOutlined
              style={{ fontSize: '16px', marginLeft: '5px', marginTop: '5px' }}
              onClick={handleShowVoiceOver}
            />
          )}
        </div>
        <div className={'voice-overs-wrapper'}>
          <div className={`voice-overs ${showVoiceOver ? 'visible' : ''}`}>
            <p
              style={{
                color: 'black',
                fontSize: '13px',
                fontWeight: 500,
                fontFamily: 'DM Sans',
                letterSpacing: 'normal',
                marginTop: '10px',
              }}
            >
              Voice Over Volume
            </p>
            <Slider
              style={{
                width: '80%',
              }}
              min={1}
              max={100}
              onChange={onVoiceOverVolumeChange}
              tooltip={{ formatter }}
              defaultValue={aiVoiceVolume}
              value={typeof aiVoiceVolume === 'number' ? aiVoiceVolume : 0}
            />
            {scriptDetails.data.map((page: any, index: any) => {
              return (
                <div key={index}>
                  {isSuccess && aiVoice?.voices?.length > 0 ? (
                    <AiVoiceRow index={index} aiVoice={aiVoice} store={store} voiceOverVolume={aiVoiceVolume} globalVoice={selectedVoiceName} showPanel={true}/>
                  ) : (
                    <AiVoiceRow
                      index={index}
                      aiVoice={aiVoicesResponse}
                      store={store}
                      voiceOverVolume={aiVoiceVolume}
                      globalVoice={selectedVoiceName}
                      showPanel={true}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </Row>
    </>
  )
}
