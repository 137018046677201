/* eslint-disable react/prop-types */
/* eslint-disable no-duplicate-imports */
import { ExclamationCircleFilled } from '@ant-design/icons'
import { IS_FREE_PLATFORM, NODE_BACKEND_URL } from '../../../../../src/config'
import { useGlobalContext } from '../../../../../src/context/globalContext'
import type { GetRef, InputRef } from 'antd'
import { Form, Input, Modal, Table, message, Popconfirm, Button, Space, Popover, Row, Col } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { DeleteOutlined, PlusCircleTwoTone, DownOutlined, UpOutlined } from '@ant-design/icons'
import { handleDeletePage } from '../../../../../src/shared/utils/polotno/pageOperations'
import Title from 'antd/es/typography/Title'
import { useGetVoices } from '../../../../../src/api/elevenLabs/getVoices'
import { AiVoiceRow } from '../../../../../src/features/video/customVideoTimeline/cardRows/aiVoiceRow'
import { EditOutlined } from '@ant-design/icons'
import { VideoPanel } from '../customPolotno/customVideoSearchPanel'
import FootageSelection from '../../../../../src/features/video/customVideoTimeline/cardRows/footageModal'
import { AudioSidebar } from '../../../../../src/features/rightSidebar/audioPanel'
import { BackgroundMusicComp } from '../../../../../src/features/rightSidebar/backgroundMusic/backgroundMusic'
import { TransitionRow } from '../../../../../src/features/video/customVideoTimeline/cardRows/transitionRow'

type FormInstance<T> = GetRef<typeof Form<T>>

const EditableContext = React.createContext<FormInstance<any> | null>(null)

// majority of this component is made using the documentation provided by ant design https://ant.design/components/table

interface Item {
  key: number
  voiceOver: string;
  textOverlay: string;
  Operations: string;

}

interface EditableRowProps {
  index: number
}

const EditableRow = React.memo(({ index, ...props }: EditableRowProps) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} data-row-index={index} />
      </EditableContext.Provider>
    </Form>
  )
})

EditableRow.displayName = 'EditableRow'

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: keyof Item
  record: Item
  handleSave: (record: Item, index: number) => void
  handleAdblockChange: (newAd: string, index: number) => void
  handleTextOverlayChange: (newText: string, index: number) => void
  handleVoiceOverChange: (newVoiceOver: string, index: number) => void
  handleOnScreenVisualChange: (newVisualOverlay: string, index: number) => void
  index: number
}

const EditableCell = React.memo(
  ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    handleAdblockChange,
    handleTextOverlayChange,
    handleVoiceOverChange,
    handleOnScreenVisualChange,
    index,
    ...restProps
  }: EditableCellProps) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef<InputRef>(null)
    const form = useContext(EditableContext)!

    useEffect(() => {
      if (editing) {
        inputRef.current?.focus()
      }
    }, [editing])

    const toggleEdit = () => {
      setEditing(!editing)
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
    }

    // a custom save function made that allows us to make changes according to what column and row has been edited by the user
    const customSave = async (dataIndex: string) => {
      try {
        const values = await form.validateFields()

        toggleEdit()

        switch (dataIndex) {
          // case 'adBlockType':
          //   if(values[dataIndex] === 'Enter Scene name' || values[dataIndex].length<2) {
          //     message.error('Please enter scene name')
          //     return
          //   }
          //   if (record[dataIndex] === values[dataIndex]) {
          //     message.error('No changes detected. Please modify the value before saving.')
          //     form.setFieldsValue({ [dataIndex]: record[dataIndex] })
          //     toggleEdit()
          //     return
          //   } else {
          //     Modal.confirm({
          //       title: 'Are you sure you want to edit this?',
          //       content: `You are about to change the on-screen visual from "${record[dataIndex]}" to "${values[dataIndex]}". Are you sure you want to proceed?`,
          //       icon: <ExclamationCircleFilled />,
          //       onOk() {
          //         handleAdblockChange(values.adBlockType, record.key)
          //         handleSave({ ...record, ...values }, index)
          //       },
          //       onCancel() {
          //         form.setFieldsValue({ [dataIndex]: record[dataIndex] })
          //         toggleEdit()
          //       },
          //     })
          //   }
          //   break
          // case 'onScreenVisual':
          //   if(values[dataIndex] === 'Enter Description' || values[dataIndex].length<10){
          //     message.error('Please enter at least 10 characters in the description.')
          //     return
          //   } 
          //   if (record[dataIndex] === values[dataIndex]) {
          //     message.error('No changes detected. Please modify the value before saving.')
          //     form.setFieldsValue({ [dataIndex]: record[dataIndex] })
          //     toggleEdit()
          //     return
          //   } else {
          //     Modal.confirm({
          //       title: 'Are you sure you want to edit this?',
          //       content: `You are about to change the on-screen visual from "${record[dataIndex]}" to "${values[dataIndex]}". Are you sure you want to proceed?`,
          //       icon: <ExclamationCircleFilled />,
          //       onOk() {
          //         handleOnScreenVisualChange(values.onScreenVisual, record.key)
          //         handleSave({ ...record, ...values }, index)
          //       },
          //       onCancel() {
          //         form.setFieldsValue({ [dataIndex]: record[dataIndex] })
          //         toggleEdit()
          //       },
          //     })
          //   }
          //   break
          case 'textOverlay':
            if (record[dataIndex] === values[dataIndex]) {
              message.error('No changes detected. Please modify the value before saving.')
              form.setFieldsValue({ [dataIndex]: record[dataIndex] })
              toggleEdit()
            } else {
              handleTextOverlayChange(values.textOverlay, record.key)
              handleSave({ ...record, ...values }, index)
            }
            break
          case 'voiceOver':
            if (record[dataIndex] === values[dataIndex]) {
              message.error('No changes detected. Please modify the value before saving.')
              form.setFieldsValue({ [dataIndex]: record[dataIndex] })
              toggleEdit()
              return
            } else {
              handleVoiceOverChange(values.voiceOver, record.key)
              handleSave({ ...record, ...values }, index)
            }

            break

          default:
            console.log('Unknown field', values)
        }

        toggleEdit()
        // console.log(record, values)
      } catch (errInfo) {
        console.log('Save failed:', errInfo)
      }
    }

    let childNode = children
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[{ required: true, message: `${title} is required.` }]}
        >
          <Input.TextArea
            ref={inputRef}
            autoSize={{ minRows: 1, maxRows: 6 }}
            onBlur={() => customSave(dataIndex)}
          />
        </Form.Item>
      ) : (
        <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      )
    }

    return <td {...restProps}>{childNode}</td>
  }
)

EditableCell.displayName = 'EditableCell'

type EditableTableProps = Parameters<typeof Table>[0]

interface DataType {
  key: number
  voiceOver: string
  textOverlay: string
  index: number

}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

interface ScriptContainerProps {
  store: any
  setUpdatedScriptDetails: any
  setRegeneratedScriptDetails: any
  setConfirmLoading: any
  setNewSceneAdded: any
}

export const ScriptContainer: React.FC<ScriptContainerProps> = ({
  store,
  setUpdatedScriptDetails,
  setRegeneratedScriptDetails,
  setConfirmLoading,
  setNewSceneAdded
}) => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    UndoStack: [undoStack, setUndoStack],
    RedoStack: [redoStack, setRedoStack],
    VideoStyle: [videoStyle],
    IsStoreUpdated: [isStoreUpdated, setIsStoreUpdated],
  } = useGlobalContext()

  // this maps the entire script and shows it in the table so we can pass the data to the table
  const initialData: DataType[] = scriptDetails.data.map(
    (script: { adBlockType: any; onScreenVisual: any; voiceOver: any; textOverlay: any }, index: number) => ({
      key: index,
      adBlockType: script.adBlockType,
      onScreenVisual: script.onScreenVisual,
      voiceOver: script.voiceOver,
      textOverlay: script.textOverlay,
      index: index,
    })
  )
  console.log(scriptDetails.data)
  // depending on the video style we want the tables to be editable or not
  // const [isEditable] = useState<boolean>(videoStyle === 'Simple' ? true : false)
  const [isEditable] = useState<boolean>(true)
  // we set the data source for table
  const [dataSource, setDataSource] = useState<DataType[]>(initialData)
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const [footageVisible, setFootageVisible] = useState(false)
  const [expanded, setExpanded] = useState<any>(-1)

  const expand = (index: any) => {
    if (expanded === index) setExpanded(undefined);
    else setExpanded(index);
  };
  // this shows what columns and rows are to be shown in the table
  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean
    dataIndex: string
  })[] = [
    {
      title: 'Scene no',
      dataIndex: 'sceneno',
      width: '10%',
      fixed: 'left',
      
      render: (_, record) => (<p>{record.key+1}</p>),
    },
      {
        title: 'Scene Description',
        dataIndex: 'voiceOver',
        width: '30%',
        fixed: 'left',
        editable: isEditable,
        render: (value, text ) => (
          // console.log(text)
         
          <>
          {value === '' ? (
        <p >(pause)</p>
          ):(<p >{value}</p>)}
         
          </>
        ),
      },

    ]


  // we only want to show the text-overlay in simple video style

  if (videoStyle === 'Simple') {

    defaultColumns.push(
      {
        title: 'Footage (Search Keyword)',
        dataIndex: 'footage',
        key: 'footage',
        width: '40%',
        fixed: 'left',
        // render:(_, record:any, index:any)=>(
        //   <DeleteOutlined onClick={()=>handleDelete('action', index)} style={{color:'red'}}/>
        // )
        render: (_, record) =>
          dataSource.length >= 1 ? (
            <Row gutter={4} style={{ flexDirection: 'row', paddingBottom: '3px' }}>
              {store.pages.map((page:any)=>{
                console.log(record.key)
                console.log(store.pages.toJSON())
              })}
              <Col>
                <FootageSelection
                  store={store}
                  pageID={store.pages.toJSON()[record.key].id}
                  index={record.key}
                  page={store.pages.toJSON()[record.key]}
                  searchKey={store?.pages[record.key]?.custom?.searchQuery}
                />
              </Col>
              <Col>
                <p>{store?.pages[record.key]?.custom?.searchQuery?.slice(0, 20) + '...'}</p>
              </Col>

              <Col>
              <p style={{fontSize:'11px'}}>{(store?.pages[record.key]?.duration/1000).toFixed(1)}sec</p>
              </Col>
            </Row>
          ) : null,
      },
    )
  }
  // const [continueIndex, setContinueIndex] = useState(0)

  const BusrtFootageSeries = ({ record }: any) => {
    return (
      <Row gutter={[0, 2]} style={{ flexDirection: 'column' }}>
        {scriptDetails.data[record.key].images.map((image: any, index: number) => {

          continueIndex++
          // console.log(store?.pages[continueIndex]?.custom?.searchQuery)
          const searchQuery = store?.pages[continueIndex]?.custom?.searchQuery?.slice(0, 12) + '..'
          // console.log(searchQuery)
          return (
            <Row key={index} gutter={4} style={{ flexDirection: 'row', paddingBottom: '3px' }}>

              <Col>
                <Row gutter={2}>

                  <Col style={{paddingTop:'15px'}}>
                    <span>{record.key + 1}</span><span>.</span><span>{index + 1}</span>
                  </Col>

                  <Col>
                    <FootageSelection
                      store={store}
                      pageID={store.pages[continueIndex].id}
                      index={record.key}
                      page={store.pages[continueIndex]}
                      searchKey={store?.pages[continueIndex]?.custom?.searchQuery}
                    // searchKey={store?.pages[continueIndex]?.custom?.searchQuery}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <p>{store?.pages[continueIndex]?.custom?.searchQuery?.slice(0, 20) + '..'}</p>
              </Col>
              <Col>
              <p style={{fontSize:'11px'}}>{(store?.pages[continueIndex]?.duration/1000).toFixed(1)}sec</p>
              </Col>
            </Row>
          )
        })}
      </Row>
    )

  }
  let continueIndex = -1
  if (videoStyle === 'Burst') {
    defaultColumns.push(
      {
        title: 'Footage (Search Keyword)',
        dataIndex: 'footage',
        key: 'footage',
        width: '40%',
        fixed: 'left',
        // render:(_, record:any, index:any)=>(
        //   <DeleteOutlined onClick={()=>handleDelete('action', index)} style={{color:'red'}}/>
        // )
        render: (_, record) =>
          dataSource.length >= 1 ? (
            <BusrtFootageSeries record={record} />
          ) : null,
      },
    )
  }
  defaultColumns.push(
    {
      title: 'AI Speaker',
      dataIndex: 'speaker',
      key: 'speaker',
      width: '25%',
      fixed: 'left',
      // render:(_, record:any, index:any)=>(
      //   <DeleteOutlined onClick={()=>handleDelete('action', index)} style={{color:'red'}}/>
      // )
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <AiVoiceRow index={record.key} aiVoice={aiVoice} store={store} voiceOverVolume={0.7} globalVoice={""} showPanel={false} />
          // <BusrtFootageSeries record={record} />
        ) : null,
    },
  )

  if(videoStyle === 'Burst'){
    defaultColumns.push({
      title: 'Onscreen Text',
      dataIndex: 'textOverlay',
      width: '20%',
      fixed: 'left',
      // editable: isEditable,
      render: (value, text ) => (
        // console.log(text)
        <>
         <p style={{color:'#c3bcbc'}}>{value}</p>
        </>
      ),
    })
  }
  if(videoStyle === 'Simple'){
    defaultColumns.push({
      title: 'AI Text',
      dataIndex: 'textOverlay',
      width: '20%',
      fixed: 'left',
      editable: isEditable,
    })
  }

  const { data: aiVoice, isLoading, isSuccess } = useGetVoices()
  const MyPopoverContent = ({ key }: any) => {

    return (
      <>{key}</>
    )
  }

  // <AiVoiceRow index={key} aiVoice={aiVoice} store={store} voiceOverVolume={0.7} globalVoice={""} showPanel={false}/>


  const ActionPopOver = ({ index }: any) => {
    return (
      // <AudioSidebar store={store}/>
      <Row gutter={16} style={{ flexDirection: 'row' }}>
        {/* <Col>
          <h3
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans',
              letterSpacing: 'normal',
              marginBottom: '10px',
            }}
          >
            {'Change Speaker'}
          </h3>
          <AiVoiceRow index={index} aiVoice={aiVoice} store={store} voiceOverVolume={0.7} globalVoice={""} showPanel={false} />
        </Col> */}
        <Col>
          <BackgroundMusicComp store={store} />
        </Col>
        <Col>
          <TransitionRow pageID={index} />
        </Col>

      </Row>
    )
  }
  defaultColumns.push(
    {
      title: 'Advanced',
      dataIndex: 'more',
      key: 'more',
      width: '15%',
      align: 'center',
      // render:(_, record:any, index:any)=>(
      //   <DeleteOutlined onClick={()=>handleDelete('action', index)} style={{color:'red'}}/>
      // )
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <>
            {/* <Popover key={record.id} content={
              <>
                <ActionPopOver index={record.key} />

              </>

            } title='Actions' trigger='click'>
              <PlusCircleTwoTone style={{ color: 'purple', fontSize: '150%', paddingRight: '10px', paddingLeft: '10px' }} />
            </Popover>{" "} */}
            {expanded == record.key ? <UpOutlined onClick={() => expand(record.key)} /> : <DownOutlined onClick={() => expand(record.key)} />}

            {/* <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
              <DeleteOutlined style={{ color: 'red', fontSize: '150%' }} />
            </Popconfirm> */}

          </>
        ) : null,
    },

  )
  defaultColumns.push(
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      fixed: 'left',
      // render:(_, record:any, index:any)=>(
      //   <DeleteOutlined onClick={()=>handleDelete('action', index)} style={{color:'red'}}/>
      // )
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <>
            {/* <Popover key={record.id} content={
              <>
                <ActionPopOver index={record.key} />

              </>

            } title='Actions' trigger='click'>
              <PlusCircleTwoTone style={{ color: 'purple', fontSize: '150%', paddingRight: '10px', paddingLeft: '10px' }} />
            </Popover>{" "} */}
            {/* <DownOutlined onClick={expand}/> */}
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
              <DeleteOutlined style={{ color: 'red', fontSize: '150%' }} />
            </Popconfirm>

          </>
        ) : null,
    },

  )


  function handleDelete(index: number) {
    if (dataSource.length < 3) {
      if (videoStyle === 'Burst') {
        message.warning("At least, you should keep 2 blocks.")
      } else {
        message.warning("At least, you should keep 2 scenes.")
      }
      return
    }
    const newData = [...dataSource]; // Create a copy of the dataSource
    newData.splice(index, 1); // Remove the element at index
    setDataSource(newData);
    setRegeneratedScriptDetails(newData)
    if (!(index > scriptDetails.data.length - 1)) {
      handleDeletePage(
        store,
        index,
        scriptDetails,
        setScriptDetails,
        setUndoStack,
        videoStyle,
        isStoreUpdated,
        setIsStoreUpdated,
        videoStyle === 'Burst'
      )
    }

  }
  const handleAdd = () => {
    const index = dataSource.length
    const newData: DataType = {
      key: index,
      voiceOver: 'Enter Voice over',
      textOverlay: 'Enter Text overlay',

      index: index
    };
    setNewSceneAdded(true)
    setDataSource([...dataSource, newData]);
    // Scroll to the new row after a slight delay for rendering
    setTimeout(() => {
      const tableBody = document.querySelector('.ant-table-body') as HTMLElement;
      if (tableBody) {
        tableBody.scrollTop = tableBody.scrollHeight;
      }
    }, 100); // Adjust delay based on rendering performance
  };

  function handleAdblockChange(newAdblock: string, index: number) {
    console.log(newAdblock)
    setRegeneratedScriptDetails((prevDetails: any) => {
      const updatedDetails = [...prevDetails]
      if (updatedDetails[index]) {
        updatedDetails[index] = {
          ...updatedDetails[index],
          adBlockType: newAdblock,
          isUpdated: {
            ...updatedDetails[index].isUpdated,
            isAdBlockUpdated: true,
          },
        }
      } else {
        updatedDetails[index] = {
          ...updatedDetails[index],
          adBlockType: newAdblock,
          isUpdated: {
            isUpdated: true,
            isAdBlockUpdated: true,
          },
        }
      }

      console.log(updatedDetails)
      return updatedDetails
    })
  }
  // if MEDIA DESCRIPTION is changed, we make a api call to the backend which generates new search queries for video or images, depending on the videoStyle
  const handleOnScreenVisualChange = async (newVisualOverlay: string, index: number) => {

    const postBody = {
      visualOverlay: newVisualOverlay,
      previousScript: scriptDetails.data[index],
    }
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    })
    console.log(newVisualOverlay)
    try {
      setConfirmLoading(true)
      const response = await localEndPoint.post('/api/v1/openAi/searchQueries', postBody)
      // setting up the new search queries in the regenerated script
      // we add the voice over in the regenerated script we add isScreenVisualUpdated: true, in isUpdated so we can change the MEDIA DESCRIPTION when we press the save button

      if (response.data) {
        setRegeneratedScriptDetails((prevDetails: any) => {
          const updatedDetails = [...prevDetails]
          if (updatedDetails[index]) {
            updatedDetails[index] = {
              ...updatedDetails[index],
              onScreenVisual: newVisualOverlay,
              searchQueries: response.data.data.searchQueries,
              images: response.data.data.images,
              isUpdated: {
                ...updatedDetails[index].isUpdated,
                isScreenVisualUpdated: true,
              },
            }
          }
          else {
            updatedDetails[index] = {
              ...updatedDetails[index],
              onScreenVisual: newVisualOverlay,
              searchQueries: response.data.data.searchQueries,
              images: response.data.data.images,
              isUpdated: {
                isUpdated: true,
                isScreenVisualUpdated: true,
              },
            }
          }

          return updatedDetails
        })
        setConfirmLoading(false)
      }
    } catch (error) {
      console.error('Failed to update on-screen visual:', error)
    }
  }

  // if the text overlay is changed then we add the new text in the regenerated script
  // we add the voice over in the regenerated script we add isTextOverlayUpdated: true in isUpdated so we can change the textOverlay when we press the save button
  function handleTextOverlayChange(newTextOverlay: string, index: number) {
    console.log(newTextOverlay)
    setRegeneratedScriptDetails((prevDetails: any) => {
      const updatedDetails = [...prevDetails]
      if (updatedDetails[index]) {
        updatedDetails[index] = {
          ...updatedDetails[index],
          textOverlay: newTextOverlay,
          isUpdated: {
            ...updatedDetails[index].isUpdated,
            isTextOverlayUpdated: true,
          },
        }
      } else {
        updatedDetails[index] = {
          ...updatedDetails[index],
          textOverlay: newTextOverlay,
          isUpdated: {
            isUpdated: true,
            isTextOverlayUpdated: true,
          },
        }
      }

      return updatedDetails
    })
  }



  // if the voice over is changed
  // we add the voice over in the regenerated script we add isVoiceOverUpdated: true in isUpdated so we can change the voiceOver when we press the save button
  const handleVoiceOverChange = async (newVoiceOver: string, index: number) => {
    if (newVoiceOver === 'Enter Voice over' || newVoiceOver.length < 2) return
    const postBody = {
      voiceOver: newVoiceOver,
      previousScript: scriptDetails.data[index],
    }
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    })
    console.log(newVoiceOver)
    try {
      setConfirmLoading(true)
      const response = await localEndPoint.post('/api/v1/openAi/searchQueries', postBody)
      console.log(typeof (response.data))
      if (typeof (response.data) === 'string' || !response.data.data.searchQueries || !response.data.data.images) {
        handleVoiceOverChange(newVoiceOver, index)
      } else {
        if (response.data) {
          setRegeneratedScriptDetails((prevDetails: any) => {
            const updatedDetails = [...prevDetails]
            if (updatedDetails[index]) {
              updatedDetails[index] = {
                ...updatedDetails[index],
                voiceOver: newVoiceOver,
                searchQueries: response.data.data.searchQueries,
                images: response.data.data.images,
                isUpdated: {
                  ...updatedDetails[index].isUpdated,
                  isVoiceOverUpdated: true,
                },
              }
            } else {
              updatedDetails[index] = {
                ...updatedDetails[index],
                adBlockType: response.data.data.adBlockType,
                onScreenVisual: response.data.data.onScreenVisual,
                voiceOver: newVoiceOver,
                textOverlay: response.data.data.textOverlay,
                searchQueries: response.data.data.searchQueries,
                images: response.data.data.images,
                isUpdated: {
                  isUpdated: true,
                  isVoiceOverUpdated: true,
                },
              }
            }
            return updatedDetails
          })
          setConfirmLoading(false)
        }
      }


    } catch (error) {
      message.error("Failed to add scene, Please try again")
      console.error('Failed to update on-screen visual:', error)
    }
  }

  // this saves any change thats made in the script table
  const handleSave = (row: DataType) => {
    console.log(row)
    const newData = [...dataSource]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]

    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    setDataSource(newData)
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        handleAdblockChange,
        handleTextOverlayChange,
        handleVoiceOverChange,
        handleOnScreenVisualChange,
        index: col.rowSpan,
      }),
    }
  })
  const [form] = Form.useForm()
  const onFinish = () => {
    console.log("--")
  }
  return (
    <div>
      <>
        <Form form={form} onFinish={onFinish}>
          <Table
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={dataSource}
            pagination={false}
            scroll={{ y: 500 }}
            expandable={{
              expandedRowRender: (record) => <ActionPopOver index={record.key} />,
              showExpandColumn:false,
           
            }}
            expandedRowKeys={[expanded]}
            columns={columns as ColumnTypes}

          />
        </Form>
      </>
      <Space style={{ marginBottom: 16 }}>
        <Button
          onClick={handleAdd}
          style={{ background: 'transparent', borderColor: 'var(--color-primary-500)', color: 'var(--color-primary-500)', marginTop: '5px' }}
        >Add New Scene</Button>
        {/* <Title level={5}>*Please press enter after completing every input.</Title> */}
      </Space>

    </div>
  )
}




