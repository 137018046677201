import { NODE_BACKEND_URL } from '../config'
import axios, { AxiosRequestConfig } from 'axios'
import { useQuery } from 'react-query'
import { storyBlocksExpires, storyBlocksHmac } from '../config/storyblocks'
import { generateUniqueId } from '../shared/utils/core'
import { getDomainID, qaBackendApiInstance } from '@src/config/config'

export const useGetDalleAiImages = (
  query = '', // Search query, default empty to fetch random videos
  orientation=''
) => {
   
    const size = orientation === 'vertical' ? '1024x1792' : '1792x1024' 
    const postBody={
        prompt: query,
        size:size
    }
    const token = localStorage.getItem('accessToken')
    // const config: AxiosRequestConfig = {
    //   headers: {
    //     'Authorization': `Bearer ${token}`,
    //   },
    // }

  return useQuery(
    ['fetchAiImages', query], // Query key, includes dependencies
    async () => {
      const response = await axios.post(`${NODE_BACKEND_URL}api/v1/openAi/dalle-image`, postBody)
    //   return response.data
    const params={
        image_url : response.data,
        domain_id : getDomainID()
    }
      const uploadAsset = await qaBackendApiInstance.post(
        `/asset/upload-from-url`,
        params,
        {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      }
      )
     
      return uploadAsset.data.image_url
    },
    {
      // Optional settings, for example:
      enabled: !!query, // Only run query if a search term is provided
      // keepPreviousData: true, // Keep previous data while fetching the next page for pagination
      // staleTime: 5 * 60 * 1000, // Data freshness duration
    }
  )
}
