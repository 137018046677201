import { IS_FREE_PLATFORM } from '../../config'
import { calculateYPosition } from './core'
import { addSpanTagToText } from './formatString'
import { hexToRgb } from './polotnoStore'

export const generateSubtitles = async (
  font?: string,
  wpl?: number,
  fontSize?: number,
  store?: any,
  wordsPerLine?: number,
  fontTemplate?: any,
  showSubtitles?: boolean,
  yAxisPosition?: number,
  setYAxisPosition?: any,
  setScriptDetails?: any,

) => {
  await store.addFont({
    fontFamily: 'Konga next',
    url: 'fonts/Konga Next.otf',
  })
  await store.addFont({
    fontFamily: 'THE BOLD FONT',
    url: 'fonts/THE BOLD FONT.otf',
  })

  await Promise.all(
    store.pages.map(async (page: any, index: number) => {
      const { custom } = page
      const { captions } = custom
      // if (!captions && !captions.length) return
      if (!captions || !captions.length) {
        return Promise.resolve()
      }

      const { words } = JSON.parse(JSON.stringify(captions[0]))
      const elementID = 'captionText' + page.id
      const xAxis = Math.floor(Math.random() * 200);
      const captionString = addSpanTagToText(
        words
          .splice(0, wpl ? wpl : wordsPerLine)
          .map((word: any) => word.word)
          .join(' '),
        fontTemplate.highlightTextStyleColor,
        fontTemplate.highlightTextBackground
        // '#FF0000'
      )
      if (!page.children.filter((element: any) => element.id === elementID).length) {
        page.addElement({
          text: captionString,
          fill: hexToRgb(fontTemplate.fontColor),
          backgroundColor: fontTemplate.backgroundColor !== '' ? hexToRgb(fontTemplate.backgroundColor) : '',
          // backgroundColor: hexToRgb('#7ED321'),
          // backgroundEnabled: true,
          backgroundEnabled: fontTemplate.backgroundColor !== '' ? true : false,
          rotation: fontTemplate.rotateX,
          fontFamily: font ? font : fontTemplate.font,
          fontSize: fontSize ? fontSize : fontTemplate.fontSize,
          id: elementID,
          type: 'text',
          x: 0,
          width: store.width,
          draggable: false,
          contentEditable: false,
          styleEditable: false,
          resizable: false,
          selectable: false,
          opacity: showSubtitles ? 1 : 0,
          strokeWidth: fontTemplate?.strokeWidth,
          stroke: fontTemplate?.stroke,
          border: fontTemplate.border,
          borderRadius: fontTemplate.borderRadius,
          background: fontTemplate.background
          // backgroundCornerRadius: 10
        })
        const yPosition = calculateYPosition(
          store.height,
          store.getElementById(elementID).height,
          IS_FREE_PLATFORM === 'true' ? 40 : 10
        )
        store.getElementById(elementID).set({
          y: yPosition,
        })
        setYAxisPosition(yPosition)
      } else {
        store.getElementById(elementID).set({
          text: captionString,
          fill: hexToRgb(fontTemplate.fontColor),
          backgroundColor: fontTemplate.backgroundColor !== '' ? hexToRgb(fontTemplate.backgroundColor) : '',
          backgroundEnabled: fontTemplate.backgroundColor !== '' ? true : false,
          fontFamily: font ? font : fontTemplate.font,
          fontSize: fontSize ? fontSize : fontTemplate.fontSize,
          id: elementID,
          type: 'text',
          x: 0,
          opacity: showSubtitles ? 1 : 0,
          strokeWidth: fontTemplate?.strokeWidth,
          stroke: fontTemplate?.stroke,
          rotation:fontTemplate.rotateX
        })
      }

      let updatedTemplate = { ...page.custom.template, ...fontTemplate, position: yAxisPosition }

      if (font) updatedTemplate = { ...updatedTemplate, font: font }
      if (wpl) updatedTemplate = { ...updatedTemplate, wordsPerLine: wpl }
      if (fontSize) updatedTemplate = { ...updatedTemplate, fontSize: fontSize}

      page.set({ custom: { ...page.custom, template: updatedTemplate } })
      // updatedTemplate = {...updatedTemplate, highlightTextStyleColor:'#FF0000'}
      await setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }
        return { ...updatedScript, template: updatedTemplate }
      })
      // console.log(updatedTemplate)
      if (showSubtitles) {
        page.set({ custom: { ...page.custom, showSubtitles: true } })
        await setScriptDetails((prevScript: { data: any }) => {
          const updatedScript = { ...prevScript }

          return { ...updatedScript, showSubtitles: true }
        })
      }
    
    })
  )
}
